import { useState, useCallback, useRef, useEffect } from 'react';
import axios from "axios";
import { useAuth0 } from "../../../react-auth0-spa";
import { useAppContext } from "../../../context";
import config from "../../../api_config.json";
import { dynamicQuery } from '../queries';

/**
 * Hook that provides dynamic filtering with a single GraphQL query
 * Builds the where clause dynamically based on active filters and search term
 * Supports independent logical operations (AND, OR) for each filter
 */
export const useDynamicFilter = (
  setLoading,
  setOrgs,
  setPagination,
  setFilteredOrgsCount
) => {
  const [searchValue, setSearchValue] = useState("");
  const [activeFilters, setActiveFilters] = useState([]);
  const searchTimeoutRef = useRef(null);
  
  const { getTokenSilently } = useAuth0();
  const { isProd } = useAppContext();
  const env = isProd ? "prod" : "dev";
  const graphqlUrl = `${config.baseUrl[env]}/graphql?raw=true`;
  
  // Track current pagination state locally
  const [currentPagination, setCurrentPagination] = useState({
    current: 1,
    pageSize: 10
  });

  // Update local pagination when the parent state changes
  useEffect(() => {
    setPagination(prev => {
      setCurrentPagination({
        current: prev.current,
        pageSize: prev.pageSize
      });
      return prev;
    });
  }, [setPagination]);

  /**
   * Build a dynamic where clause based on search term and active filters
   * Each filter uses its own logical operator
   */
  const buildWhereClause = useCallback((params = {}) => {
    // If params.searchTerm is explicitly an empty string, use it to clear the search
    // Otherwise, if undefined or null, fall back to the current searchValue
    const searchTerm = params.searchTerm !== undefined ? params.searchTerm.trim() : searchValue.trim();
    const filters = params.filters || activeFilters;
    
    // Start with an empty where clause
    const whereClause = {};
    
    // Add search condition if search term exists
    if (searchTerm) {
      whereClause.nome = { matchesRegex: searchTerm, options: "i" };
    }
    
    // Add filter conditions
    if (filters && filters.length > 0) {
      // If we already have a search term, we need to combine it with filters
      const searchCondition = searchTerm 
        ? { nome: { matchesRegex: searchTerm, options: "i" } } 
        : null;
      
      // Debug each filter
      filters.forEach(filter => {
        console.log(`Processing filter: column=${filter.column}, operator=${filter.operator}, value=${filter.value}`);
      });
      
      // Group filters by logical operator
      const filtersByOperator = filters.reduce((acc, filter) => {
        const op = filter.logicalOperator || "AND";
        if (!acc[op]) acc[op] = [];
        
        const condition = {};
        // First check if it's an exists operator
        const operator = filter.operator || 'equalTo';
        
        if (operator === 'exists') {
          console.log(`Setting exists: ${filter.value} for field ${filter.column}`);
          condition[filter.column] = { exists: filter.value };
        }
        // Then handle other operators
        else if (typeof filter.value === 'boolean') {
          condition[filter.column] = { equalTo: filter.value };
        } else {
          // Use the selected operator from the filter
          let operatorConfig;
          
          switch (operator) {
            case 'notEqualTo':
              operatorConfig = { notEqualTo: filter.value };
              break;
            case 'greaterThan':
              operatorConfig = { greaterThan: filter.value };
              break;
            case 'lessThan':
              operatorConfig = { lessThan: filter.value };
              break;
            case 'greaterThanOrEqualTo':
              operatorConfig = { greaterThanOrEqualTo: filter.value };
              break;
            case 'lessThanOrEqualTo':
              operatorConfig = { lessThanOrEqualTo: filter.value };
              break;
            case 'contains':
              operatorConfig = { includes: filter.value };
              break;
            case 'equalTo':
            default:
              operatorConfig = { equalTo: filter.value };
              break;
          }
          
          condition[filter.column] = operatorConfig;
        }
        acc[op].push(condition);
        
        return acc;
      }, {});
      
      // Create the overall structure for the where clause
      const conditions = [];
      
      // Add search condition if it exists
      if (searchCondition) {
        conditions.push(searchCondition);
      }
      
      // Process AND conditions
      if (filtersByOperator.AND && filtersByOperator.AND.length > 0) {
        if (filtersByOperator.AND.length === 1 && conditions.length === 0) {
          // If there's only one AND condition and no search, apply it directly
          const condition = filtersByOperator.AND[0];
          const key = Object.keys(condition)[0];
          whereClause[key] = condition[key];
        } else {
          // Multiple AND conditions or combined with search
          conditions.push(...filtersByOperator.AND);
        }
      }
      
      // Process OR conditions
      if (filtersByOperator.OR && filtersByOperator.OR.length > 0) {
        if (filtersByOperator.OR.length === 1 && conditions.length === 0) {
          // If there's only one OR condition and no other conditions, apply it directly
          const condition = filtersByOperator.OR[0];
          const key = Object.keys(condition)[0];
          whereClause[key] = condition[key];
        } else if (filtersByOperator.OR.length > 1) {
          // Multiple OR conditions
          conditions.push({ OR: filtersByOperator.OR });
        } else {
          // Single OR condition combined with other conditions
          conditions.push(filtersByOperator.OR[0]);
        }
      }
      
      // Combine all conditions with AND if we have multiple conditions
      if (conditions.length > 1) {
        whereClause.AND = conditions;
      } else if (conditions.length === 1 && !whereClause[Object.keys(conditions[0])[0]]) {
        // If we only have one condition and it's not already directly applied
        const condition = conditions[0];
        const key = Object.keys(condition)[0];
        
        // Check if it's an OR condition
        if (key === 'OR') {
          whereClause.OR = condition.OR;
        } else {
          whereClause[key] = condition[key];
        }
      }
    }
    
    console.log('Final where clause:', JSON.stringify(whereClause, null, 2));
    return whereClause;
  }, [searchValue, activeFilters]);

  /**
   * Fetch data with the dynamic where clause
   */
  const fetchFilteredData = useCallback(async (params = {}) => {
    try {
      setLoading(true);
      const token = await getTokenSilently();
      
      // Build the where clause
      const where = buildWhereClause(params);
      
      // Determine sort order
      let order = "createdAt_DESC"; // default order
      if (params.sortField === "nome") {
        order = params.sortOrder === "ascend" ? "nome_ASC" : "nome_DESC";
      }
      
      // Calculate skip based on page and pageSize
      const currentPage = params.currentPage || currentPagination.current || 1;
      const pageSize = params.pageSize || currentPagination.pageSize || 10;
      const skip = (currentPage - 1) * pageSize;
      
      // Set up variables
      const variables = {
        first: pageSize,
        skip: skip,
        where: where,
        order: order
      };
      
      console.log('Executing query with variables:', JSON.stringify(variables, null, 2));
      
      // Execute the query
      const response = await axios({
        method: "post",
        url: graphqlUrl,
        data: {
          query: dynamicQuery,
          variables: variables
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data?.organizzaziones) {
        const { edges, count } = response.data.organizzaziones;
        
        const results = edges.map(edge => edge.node);
        setOrgs(results);
        
        // Update pagination and filtered count
        const totalResults = count || results.length;
        setPagination(prev => ({
          // Preserve the current page if it's passed in params, otherwise keep the previous value
          current: currentPage,
          pageSize: pageSize,
          total: totalResults,
        }));
        
        setFilteredOrgsCount(totalResults);
      }
      
      setLoading(false);
    } catch (error) {
      console.error("Error in dynamic filter:", error);
      console.error("Error details:", error.response?.data || error.message);
      setLoading(false);
    }
  }, [
    getTokenSilently,
    graphqlUrl,
    buildWhereClause,
    setLoading,
    setOrgs,
    setPagination,
    setFilteredOrgsCount,
    currentPagination.current,
    currentPagination.pageSize
  ]);

  /**
   * Handle search input changes with debounce
   */
  const handleSearchChange = useCallback((e) => {
    const value = e.target.value;
    
    // Immediately update the search value state to ensure it's updated before any query runs
    setSearchValue(value);
    
    // Reset pagination to page 1 when search term changes
    setPagination(prev => ({
      ...prev,
      current: 1
    }));
    
    // Clear any existing timeout
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }
    
    // Set a new timeout for debouncing
    searchTimeoutRef.current = setTimeout(() => {
      // When the value is empty (cleared), explicitly pass empty string to ensure it overrides previous values
      fetchFilteredData({ 
        searchTerm: value, 
        currentPage: 1 
      });
    }, 300);
  }, [fetchFilteredData, setPagination]);

  /**
   * Handle filter changes
   */
  const handleFilterChange = useCallback((newFilters, skipQuery = false) => {
    console.log('handleFilterChange called with', newFilters.length, 'filters, skipQuery =', skipQuery);
    console.log('Filter details:', JSON.stringify(newFilters, null, 2));
    
    // Only update state without triggering a query
    setActiveFilters(newFilters);
    
    // Reset pagination to page 1 when filters change
    setPagination(prev => ({
      ...prev,
      current: 1
    }));
    
    // Skip query if specified (e.g. when change comes from URL)
    if (skipQuery) {
      console.log('Skipping query execution because skipQuery = true');
      return;
    }
    
    // Use a timeout to batch filter changes and prevent multiple queries
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }
    
    searchTimeoutRef.current = setTimeout(() => {
      console.log('Executing query after timeout with', newFilters.length, 'filters');
      fetchFilteredData({ filters: newFilters, currentPage: 1 }); // Force page 1 when filters change
    }, 50); // Short timeout to batch changes
  }, [fetchFilteredData, setPagination]);

  /**
   * Clear all filters and search
   */
  const clearAll = useCallback(() => {
    setSearchValue("");
    setActiveFilters([]);
    
    // Reset pagination to page 1 when clearing filters
    setPagination(prev => ({
      ...prev,
      current: 1
    }));
    
    fetchFilteredData({ searchTerm: "", filters: [], currentPage: 1 });
  }, [fetchFilteredData, setPagination]);

  // Cleanup timeout on unmount
  useEffect(() => {
    return () => {
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }
    };
  }, []);

  return {
    // State
    searchValue,
    setSearchValue,
    activeFilters,
    
    // Functions
    fetchFilteredData,
    handleSearchChange,
    handleFilterChange,
    clearAll
  };
};

export default useDynamicFilter; 