import React, { useState, useCallback, useEffect, useMemo } from "react";
import {
  Button,
  Tag,
  Divider,
  Table,
  Statistic,
  Card,
  Row,
  Col,
  Spin,
} from "antd";
import { useAuth0 } from "../../../react-auth0-spa";
import format from "date-fns/format";
import { PlusOutlined } from "@ant-design/icons";
import CreditiModal from "./CreditiModal";
import config from "../../../api_config.json";
import { useAppContext } from "../../../context";

const OrgCrediti = (props) => {
  const [creditiModalVisible, setCreditiModalVisible] = useState(false);
  const [creditiModalResult, setCreditiModalResult] = useState();
  const { orgId } = props;

  // State for credits and orders
  const [credits, setCredits] = useState({
    freeCreditsAmount: 0,
    paidCreditsAmount: 0,
  });
  const [creditOrders, setCreditOrders] = useState([]);

  // Add loading states
  const [creditsLoading, setCreditsLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [createOrderLoading, setCreateOrderLoading] = useState(false);

  // State for table pagination
  const [tablePagination, setTablePagination] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
  });

  const { getTokenSilently } = useAuth0();
  const { isProd } = useAppContext();

  // Memoize pageSize to prevent unnecessary effect triggers
  const pageSize = useMemo(
    () => tablePagination.pageSize,
    [tablePagination.pageSize]
  );

  // GraphQL query for fetching RemovebgCredits
  const getCreditsQuery = `
    query getCredits($orgId: ID!, $first: Int!) {
      findRemovebgCredits(
        first: $first
        where: {org_pointer: {have: {objectId: {equalTo: $orgId}}}}
      ) {
        count
        edges {
          node {
            freeCreditsAmount
            paidCreditsAmount
          }
        }
      }
    }
  `;

  // GraphQL query for fetching RemovebgOrders with offset-based pagination
  const getRemovebgOrdersQuery = `
    query getRemovebgOrders($orgId: ID!, $first: Int!, $skip: Int) {
      findRemovebgOrders(
        first: $first
        skip: $skip
        where: {org_pointer: {have: {objectId: {equalTo: $orgId}}}}
        order: [createdAt_DESC]
      ) {
        count
        edges {
          node {
            objectId
            createdAt
            transactionDate
            freeCreditsAmount
            paidCreditsAmount
            note
            isPaid
            used_by
            paymentReference
            transactionStatus
          }
        }
      }
    }
  `;

  const fetchCredits = useCallback(async () => {
    const env = isProd ? "prod" : "dev";
    const graphqlUrl = `${config.baseUrl[env]}/graphql?raw=true`;

    try {
      setCreditsLoading(true);
      const token = await getTokenSilently();

      // Execute the GraphQL query to fetch credit amounts
      const response = await fetch(graphqlUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          query: getCreditsQuery,
          variables: {
            orgId,
            first: 10
          },
        }),
      });

      const result = await response.json();

      if (result && result.findRemovebgCredits) {
        if (
          result.findRemovebgCredits.edges &&
          result.findRemovebgCredits.edges.length > 0
        ) {
          const creditData = result.findRemovebgCredits.edges[0].node;
          setCredits({
            freeCreditsAmount: creditData.freeCreditsAmount || 0,
            paidCreditsAmount: creditData.paidCreditsAmount || 0,
          });
        }
      }

      return { success: true };
    } catch (error) {
      console.error("Error fetching credits:", error);
      return { success: false, error };
    } finally {
      setCreditsLoading(false);
    }
  }, [isProd, getTokenSilently, orgId, getCreditsQuery]);

  // Function to fetch RemovebgOrders with offset-based pagination
  const fetchOrders = useCallback(
    async (page = 1, pageSize = 5) => {
      const env = isProd ? "prod" : "dev";
      const graphqlUrl = `${config.baseUrl[env]}/graphql?raw=true`;

      try {
        setTableLoading(true);
        
        const token = await getTokenSilently();
        
        // Calculate skip based on page and pageSize
        const skip = (page - 1) * pageSize;

        // Execute the GraphQL query to fetch orders with pagination
        const response = await fetch(graphqlUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            query: getRemovebgOrdersQuery,
            variables: {
              orgId,
              first: pageSize,
              skip: skip
            },
          }),
        });

        const result = await response.json();

        if (result && result.findRemovebgOrders) {
          // Update orders data
          if (result.findRemovebgOrders.edges) {
            const orders = result.findRemovebgOrders.edges.map((edge) => ({
              ...edge.node,
              key: edge.node.objectId, // Add key for table component
            }));
            setCreditOrders(orders);
          }

          // Update pagination with total count
          if (result.findRemovebgOrders.count !== undefined) {
            setTablePagination((prev) => ({
              ...prev,
              current: page,
              pageSize: pageSize,
              total: result.findRemovebgOrders.count,
            }));
          }
        }

        return { success: true };
      } catch (error) {
        console.error("Error fetching orders:", error);
        return { success: false, error };
      } finally {
        setTableLoading(false);
      }
    },
    [isProd, getTokenSilently, orgId, getRemovebgOrdersQuery]
  );

  useEffect(() => {
    // Fetch initial data
    fetchCredits();
    // Initial fetch of orders for the first page
    fetchOrders(1, pageSize);
  }, [fetchCredits, fetchOrders, pageSize]);

  const createCreditOrder = async (values) => {
    try {
      setCreateOrderLoading(true);
      const token = await getTokenSilently();

      const payload = {
        ...values,
        orgId: orgId,
      };

      const response = await fetch(
        `${config.removebg[isProd ? "prod" : "dev"]}/synthetic-transaction`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      );

      const responseData = await response.json();

      return responseData;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setCreateOrderLoading(false);
    }
  };

  const onResultAcked = () => {
    setCreditiModalVisible(false);
    setCreditiModalResult("");
    
    // Refresh data
    fetchCredits();
    fetchOrders(1, pageSize);
  };

  const onCreate = (values) => {
    createCreditOrder(values)
      .then((response) => {
        setCreditiModalResult("OK");
        
        // Refresh data after successful creation
        fetchCredits();
        fetchOrders(1, pageSize);
      })
      .catch((error) => {
        console.error(error);
        setCreditiModalResult("ERROR");
      });
  };

  // Handle table pagination change
  const handleTableChange = (pagination) => {
    const currentPage = pagination.current;
    const pageSize = pagination.pageSize;
    
    // Update pagination state immediately to show the user feedback
    setTablePagination({
      ...pagination,
      current: currentPage,
      pageSize: pageSize,
    });
    
    // Fetch the page directly using offset-based pagination
    fetchOrders(currentPage, pageSize);
  };

  const columns = [
    {
      title: "Data",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (data) => format(new Date(data), "dd/MM/yyyy"),
    },
    {
      title: "Ora",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (data) => format(new Date(data), "HH:mm"),
    },
    {
      title: "Crediti Acquistati",
      dataIndex: "paidCreditsAmount",
      key: "paidCreditsAmount",
    },
    {
      title: "Crediti Gratuiti",
      dataIndex: "freeCreditsAmount",
      key: "freeCreditsAmount",
    },
    { title: "Note", dataIndex: "note", key: "note" },
    {
      title: "Costo",
      dataIndex: "isPaid",
      key: "isPaid",
      render: (isPaid) => {
        return (
          <Tag color={isPaid ? "green" : "blue"}>
            {isPaid ? "PAID" : "FREE"}
          </Tag>
        );
      },
    },
    {
      title: "ID Pagamento",
      dataIndex: "paymentReference",
      key: "paymentReference",
    },
    {
      title: "Stato",
      dataIndex: "transactionStatus",
      key: "transactionStatus",
      render: (transactionStatus) => {
        const colors = {
          approved: "green",
          cancelled: "volcano",
          pending: "orange",
        };

        return (
          <Tag color={colors[transactionStatus]}>
            {transactionStatus.toUpperCase()}
          </Tag>
        );
      },
    },
  ];

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
          <Card>
            <Spin spinning={creditsLoading}>
              <Statistic
                title="Gratuiti"
                value={credits.freeCreditsAmount}
                precision={0}
                valueStyle={{
                  color: credits.freeCreditsAmount > 0 ? "#3f8600" : "#cf1322",
                }}
                suffix="crediti"
              />
            </Spin>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
          <Card>
            <Spin spinning={creditsLoading}>
              <Statistic
                title="Acquistati"
                value={credits.paidCreditsAmount}
                precision={0}
                valueStyle={{
                  color: credits.paidCreditsAmount > 0 ? "#3f8600" : "#cf1322",
                }}
                suffix="crediti"
              />
            </Spin>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8} lg={6} xl={4}>
          <Card style={{ height: "100%" }}>
            <Button
              style={{ marginTop: "15px", width: "100%" }}
              type="primary"
              size="large"
              onClick={() => setCreditiModalVisible(true)}
              loading={createOrderLoading}
            >
              Crea Ordine <PlusOutlined />
            </Button>
            <CreditiModal
              onResultAcked={onResultAcked}
              creditiModalResult={creditiModalResult}
              visible={creditiModalVisible}
              onCreate={onCreate}
              onCancel={() => setCreditiModalVisible(false)}
            />
          </Card>
        </Col>
      </Row>
      <Divider />
      <Table
        dataSource={creditOrders}
        columns={columns}
        pagination={tablePagination}
        onChange={handleTableChange}
        loading={tableLoading}
      />
    </div>
  );
};

export default OrgCrediti;
