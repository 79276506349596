import React from "react";
import { Card } from "antd";

const OrgAnnunciFunebri = ({ orgId }) => {
  return (
    <Card title="Annunci Funebri" bordered={false}>
      <p>Contenuto per la sezione Annunci Funebri da implementare</p>
    </Card>
  );
};

export default OrgAnnunciFunebri; 