import React, { useState, useEffect } from "react";
import { Modal, Form, Select, Button, Input, Divider } from "antd";
import { WalletOutlined } from "@ant-design/icons";

const { Option } = Select;

const SkuWizard = ({ onGenerate, initialSku }) => {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [previewSku, setPreviewSku] = useState("");

  const serviceOptions = [
    { label: "Eterno", value: "ET" },
    { label: "Funerama", value: "FU" },
    { label: "Add-On", value: "ADD" },
    { label: "Altro", value: "__" },
  ];

  const typeOptions = [
    { label: "Standard", value: "ST" },
    { label: "Integrazione", value: "IN" },
    { label: "Altro", value: "__" },
  ];

  const formatToday = () => {
    const today = new Date();
    return `${String(today.getFullYear()).slice(-2)}-${String(
      today.getMonth() + 1
    ).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")}`;
  };

  const updatePreview = React.useCallback((changedValues, allValues) => {
    const parts = [allValues.service];
    if (allValues.moduleName?.trim()) {
      parts.push(allValues.moduleName.trim());
    }
    parts.push(allValues.type);
    parts.push(allValues.dateSuffix || formatToday()); // Use provided date or today's date

    setPreviewSku(parts.join("-"));
  }, []);

  useEffect(() => {
    if (visible && initialSku) {
      const parts = initialSku.split("-");
      if (parts.length >= 3) {
        const service = parts[0];
        const type = initialSku.slice(-11, -9);
        const dateSuffix = initialSku.slice(-8); // Extract the date part
        const moduleName = initialSku.slice(
          service.length + 1, 
          initialSku.length - 11 // Remove type-date part
        ).replace(/-$/, ''); // Remove trailing dash if present

        const values = {
          service,
          moduleName,
          type,
          dateSuffix, // Use the original date
        };

        form.setFieldsValue(values);
        updatePreview(null, values);
      }
    }
  }, [visible, initialSku, form, updatePreview]);

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      onGenerate(previewSku);
      setVisible(false);
      form.resetFields();
      setPreviewSku("");
    });
  };

  const handleModuleNameChange = (e) => {
    const value = e.target.value.toUpperCase();
    form.setFieldValue("moduleName", value);
  };

  const handlePreviewChange = (e) => {
    setPreviewSku(e.target.value.toUpperCase());
  };

  return (
    <>
      <Button
        type="link"
        icon={<WalletOutlined />}
        onClick={() => setVisible(true)}
        style={{ padding: 0 }}
      />
      <Modal
        title="Genera SKU"
        visible={visible}
        onOk={handleSubmit}
        onCancel={() => {
          setVisible(false);
          form.resetFields();
          setPreviewSku("");
        }}
        okText="Genera"
        cancelText="Annulla"
      >
        <Form form={form} layout="vertical" onValuesChange={updatePreview}>
          <Form.Item
            name="service"
            label="Servizio"
            rules={[{ required: true, message: "Seleziona il servizio" }]}
          >
            <Select placeholder="Seleziona il servizio">
              {serviceOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="moduleName" label="Nome del modulo">
            <Input
              placeholder="Inserisci il nome del modulo"
              onChange={handleModuleNameChange}
            />
          </Form.Item>

          <Form.Item
            name="type"
            label="Tipologia"
            rules={[{ required: true, message: "Seleziona la tipologia" }]}
          >
            <Select placeholder="Seleziona la tipologia">
              {typeOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item 
            name="dateSuffix" 
            label={
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <span>Data</span>
                <Button 
                  size="small" 
                  type="primary"
                  onClick={() => form.setFieldValue('dateSuffix', formatToday())}
                >
                  Imposta ad oggi
                </Button>
              </div>
            }
          >
            <Input placeholder="YY-MM-DD" />
          </Form.Item>

          <Divider />

          <Form.Item label="Anteprima SKU">
            <Input
              value={previewSku}
              onChange={handlePreviewChange}
              style={{ fontFamily: "monospace" }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default SkuWizard;
