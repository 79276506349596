export const GET_ALLEGATI = `
query findAllegati($where: AllegatiWhereInput, $first: Int!, $skip: Int) {
  allegatis(
    first: $first
    skip: $skip
    where: $where
    order:createdAt_DESC
  ) {
    edges {
      node {
        objectId
        createdAt
        updatedAt
        allegato {
          url
          name
        }
        defunto_pointer {
          objectId
        }
        isImageEnhancement
        isSmile
        isBlurBg
        isRemovebg
        isColorizeBw
        isMagicEraser
        isChangeClothes
        smileRawImage
        removebgRawImage
        colorizeBwRawImage
        enhancementRawImage
        magicEraserRawImage
        changeClothesRawImage
        condiviso
      }
    }
  }
}  
`;
