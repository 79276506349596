import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  Skeleton,
  Typography,
  Tag,
  Result,
  Button,
  Menu,
  Layout,
  Drawer,
  Select
} from "antd";
import { Link, useHistory } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import { decodeStr } from "../../utils";
import "./OrgDetailsV2.css";
import useOrgDetails from "./hooks/useOrgDetails";
// import useOrgModules from "./hooks/useOrgModules";

// Import tab components
import OrgUsage from "./tabs/OrgUsage";
import OrgServizi from "./tabs/OrgServizi";
import OrgFatture from "./tabs/OrgFatture";
import OrgUsers from "./tabs/OrgUsers";
import OrgFeatures from "./tabs/OrgFeatures";
import OrgRemovebg from "./tabs/OrgRemovebg";
import OrgBlurBg from "./tabs/OrgBlurBg";
import OrgImageEnhancement from "./tabs/OrgImageEnhancement";
import OrgGiotto from "./tabs/OrgGiotto";
import OrgAnnunciFunebri from "./tabs/OrgAnnunciFunebri";
import OrgDocumenti from "./tabs/OrgDocumenti";
import OrgTimeline from "./tabs/OrgTimeline";
import OrgFatturazioneElettronica from "./tabs/OrgFatturazioneElettronica";
import OrgCentroPrenotazioni from "./tabs/OrgCentroPrenotazioni";
import OrgJson from "./tabs/OrgJson";
import OrgRiepilogo from "./tabs/OrgRiepilogo";
import OrgCrediti from "./tabs/OrgCrediti";
import OrgColorizeBw from "./tabs/OrgColorizeBw";
import OrgSmile from "./tabs/OrgSmile";
import OrgMagicEraser from "./tabs/OrgMagicEraser";
import OrgChangeClothes from "./tabs/OrgChangeClothes";

const { Title } = Typography;
const { Sider, Content } = Layout;
const { Option } = Select;

const OrgDetailsV2 = (props) => {
  const { orgId } = props.match.params;
  const { org, loading: orgLoading } = useOrgDetails(orgId);
  // const { loading: modulesLoading } = useOrgModules(orgId);
  const history = useHistory();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [drawerVisible, setDrawerVisible] = useState(false);

  // Menu items array organized in groups
  const menuGroups = org ? [
    {
      title: "Informazioni Generali",
      items: [
        { key: "riepilogo", label: "Riepilogo" },
        { key: "usage", label: "Usage" },
        { key: "servizi", label: `Servizi (${org.percent_services_used || 0}%)` },
        { key: "users", label: "Users" },
        { key: "features", label: "Funzionalità" },
      ]
    },
    {
      title: "Intelligenza Artificiale",
      items: [
        { key: "crediti", label: "Crediti" },
        { key: "removebg", label: "Rimuovi Sfondo" },
        { key: "blurbg", label: "Sfoca Sfondo" },
        { key: "imageEnhancement", label: "Migliora Foto" },
        { key: "colorizeBw", label: "Colora B/N" },
        { key: "smile", label: "Sorriso" },
        { key: "magicEraser", label: "Pennello Correttivo" },
        { key: "changeClothes", label: "Cambia Vestiti" },
        { key: "giotto", label: "Giotto" },
      ]
    },
    {
      title: "Servizi Specifici",
      items: [
        { key: "annunci-funebri", label: "AF" },
        { key: "centro-prenotazioni", label: "Centro Prenotazioni" },
        { key: "fatturazione-elettronica", label: "Aruba" },
      ]
    },
    {
      title: "Amministrazione",
      items: [
        { key: "fatture", label: "Fatture" },
        { key: "documenti", label: "Documenti" },
        { key: "timeline-operazioni", label: "Timeline" },
        { key: "json", label: "JSON" },
      ]
    }
  ] : [];

  // Flatten menu items for mobile dropdown
  const flatMenuItems = menuGroups.flatMap(group => group.items);
  
  // Function to check if a tab key is valid
  const isValidTab = (tabKey) => {
    return flatMenuItems.some(item => item.key === tabKey);
  };

  // Initialize activeTab state
  const initialTab = props.match.params.tabName && isValidTab(props.match.params.tabName) 
    ? props.match.params.tabName 
    : "riepilogo";
  const [activeTab, setActiveTab] = useState(initialTab);

  // Redirect to default tab if no tabName is provided
  // useEffect(() => {
  //   if (!props.match.params.tabName) {
  //     try {
  //       // Use a safer approach with a setTimeout to avoid immediate navigation
  //       setTimeout(() => {
  //         history.replace(`/orgs/${orgId}/riepilogo`);
  //       }, 0);
  //     } catch (error) {
  //       console.error("Navigation error:", error);
  //     }
  //   }
  // }, [orgId, history, props.match.params.tabName]);

  // // Update active tab when URL changes
  // useEffect(() => {
  //   if (props.match.params.tabName && isValidTab(props.match.params.tabName)) {
  //     setActiveTab(props.match.params.tabName);
  //   } else {
  //     setActiveTab("riepilogo");
  //     // Redirect to default tab if the tab in URL is invalid
  //     if (props.match.params.tabName && !isValidTab(props.match.params.tabName)) {
  //       try {
  //         // Use a safer approach with a setTimeout to avoid immediate navigation
  //         setTimeout(() => {
  //           history.replace(`/orgs/${orgId}/riepilogo`);
  //         }, 0);
  //       } catch (error) {
  //         console.error("Navigation error:", error);
  //       }
  //     }
  //   }
  // }, [props.match.params.tabName, orgId, history, flatMenuItems]);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Handle menu item click
  const handleMenuClick = (e) => {
    setActiveTab(e.key);
    try {
      setTimeout(() => {
        history.push(`/orgs/${orgId}/${e.key}`);
      }, 0);
    } catch (error) {
      console.error("Navigation error:", error);
    }
    if (isMobile) {
      setDrawerVisible(false);
    }
  };

  // Handle dropdown change for mobile
  const handleDropdownChange = (value) => {
    setActiveTab(value);
    try {
      setTimeout(() => {
        history.push(`/orgs/${orgId}/${value}`);
      }, 0);
    } catch (error) {
      console.error("Navigation error:", error);
    }
  };

  // Mostra lo skeleton solo se entrambi i dati principali stanno caricando
  if (orgLoading) {
    return <Skeleton active />;
  }

  if (!org) {
    return (
      <div className="org-details-v2-container">
        <Result
          status="404"
          title="Organizzazione non trovata"
          subTitle="L'organizzazione richiesta non esiste o è stata rimossa."
          extra={
            <Button type="primary">
              <Link to="/orgs">Torna alla lista clienti</Link>
            </Button>
          }
        />
      </div>
    );
  }

  // Render the active tab content
  const renderTabContent = () => {
    switch (activeTab) {
      case "riepilogo":
        return <OrgRiepilogo org={org} />;
      case "usage":
        return <OrgUsage orgId={orgId} />;
      case "servizi":
        return <OrgServizi org={org} />;
      case "fatture":
        return <OrgFatture org={org} />;
      case "users":
        return <OrgUsers orgId={orgId} />;
      case "features":
        return <OrgFeatures orgId={orgId} org={org} />;
      case "crediti":
        return <OrgCrediti orgId={orgId} />;
      case "removebg":
        return <OrgRemovebg orgId={orgId} />;
      case "blurbg":
        return <OrgBlurBg orgId={orgId} />;
      case "imageEnhancement":
        return <OrgImageEnhancement orgId={orgId} />;
      case "colorizeBw":
        return <OrgColorizeBw orgId={orgId} />;
      case "smile":
        return <OrgSmile orgId={orgId} />;
      case "magicEraser":
        return <OrgMagicEraser orgId={orgId} />;
      case "changeClothes":
        return <OrgChangeClothes orgId={orgId} />;
      case "giotto":
        return <OrgGiotto orgId={orgId} />;
      case "annunci-funebri":
        return <OrgAnnunciFunebri orgId={orgId} />;
      case "documenti":
        return <OrgDocumenti orgId={orgId} />;
      case "timeline-operazioni":
        return <OrgTimeline orgId={orgId} />;
      case "fatturazione-elettronica":
        return <OrgFatturazioneElettronica orgId={orgId} />;
      case "centro-prenotazioni":
        return <OrgCentroPrenotazioni orgId={orgId} />;
      case "json":
        return <OrgJson org={org} />;
      default:
        return <OrgRiepilogo org={org} />;
    }
  };

  return (
    <div className="org-details-v2-container">
      <Breadcrumb style={{ marginBottom: "20px" }}>
        <Breadcrumb.Item>
          <Link to="/orgs">Organizzazioni</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{decodeStr(org.nome)}</Breadcrumb.Item>
      </Breadcrumb>

      <div className="org-header">
        <Title
          level={2}
          style={{
            marginBottom: "24px",
            color: `${org.stato === "attiva" ? "green" : "red"}`,
          }}
        >
          {decodeStr(org.nome)}
          <Tag color={org.stato === "attiva" ? "green" : "red"} style={{ marginLeft: 12 }}>
            {org.stato.toUpperCase()}
          </Tag>
        </Title>
        
        {isMobile && (
          <div className="mobile-tab-selector">
            <Select
              value={activeTab}
              onChange={handleDropdownChange}
              style={{ width: '100%', marginBottom: '20px' }}
            >
              {flatMenuItems.map(item => (
                <Option key={item.key} value={item.key}>{item.label}</Option>
              ))}
            </Select>
          </div>
        )}
      </div>

      <Layout className="org-details-layout">
        {!isMobile ? (
          <Sider width={250} className="org-details-sider" theme="light">
            <Menu
              mode="vertical"
              selectedKeys={[activeTab]}
              onClick={handleMenuClick}
              style={{ height: '100%' }}
            >
              {menuGroups.map((group, index) => (
                <Menu.ItemGroup key={`group-${index}`} title={group.title}>
                  {group.items.map(item => (
                    <Menu.Item key={item.key}>{item.label}</Menu.Item>
                  ))}
                </Menu.ItemGroup>
              ))}
            </Menu>
          </Sider>
        ) : (
          <div className="mobile-menu-button">
            <Button 
              type="primary" 
              icon={<MenuOutlined />} 
              onClick={() => setDrawerVisible(true)}
              style={{ marginBottom: '16px' }}
            >
              Menu
            </Button>
            <Drawer
              title="Menu"
              placement="left"
              onClose={() => setDrawerVisible(false)}
              open={drawerVisible}
            >
              <Menu
                mode="vertical"
                selectedKeys={[activeTab]}
                onClick={handleMenuClick}
                style={{ border: 'none' }}
              >
                {menuGroups.map((group, index) => (
                  <Menu.ItemGroup key={`group-${index}`} title={group.title}>
                    {group.items.map(item => (
                      <Menu.Item key={item.key}>{item.label}</Menu.Item>
                    ))}
                  </Menu.ItemGroup>
                ))}
              </Menu>
            </Drawer>
          </div>
        )}
        <Content className="org-details-content">
          {renderTabContent()}
        </Content>
      </Layout>
    </div>
  );
};

export default OrgDetailsV2;
