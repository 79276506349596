import React, { useState, useEffect, useCallback, useRef } from "react";
import {  Badge, Table, Typography, Tag, Space, Menu } from "antd";
import axios from "axios";
import { useAuth0 } from "../../react-auth0-spa";
import config from "../../api_config.json";
import { useAppContext } from "../../context";
import { Link, useLocation, useHistory } from "react-router-dom";
import OrgLinks from "../OrgDetails/OrgLinks";
import { decodeStr } from "../../utils";
import "./OrgsV2.css";
import queryString from "query-string";
import { countOrgsQuery } from "./queries";
import { FilterOutlined } from "@ant-design/icons";

// Import the filters
import {
  useActiveClientsFilter,
  useColumnSearch,
  useDynamicFilter
} from "./filters";
import FiltersComponent, { FilterTags } from "./FiltersComponent";

const { Title } = Typography;

// Custom filter dropdown component for Istanza column
const IstanzaFilterDropdown = ({ setSelectedKeys, confirm, clearFilters, filters, handleFilterChange, activeFilters }) => {
  const handleMenuClick = (value) => {
    // Add a filter to the top filters
    handleFilterChange([
      ...activeFilters,
      {
        id: Date.now(),
        column: "tipo_istanza",
        columnName: "Istanza",
        value: value,
        displayValue: value,
        operator: "equalTo",
        logicalOperator: "AND"
      }
    ]);
    
    // Close the dropdown
    confirm();
  };

  return (
    <div style={{ padding: 8 }}>
      <Menu>
        {filters.map(filter => (
          <Menu.Item key={filter.value} onClick={() => handleMenuClick(filter.value)}>
            {filter.text}
          </Menu.Item>
        ))}
      </Menu>
    </div>
  );
};

// Custom filter dropdown component for Stato column
const StatoFilterDropdown = ({ setSelectedKeys, confirm, clearFilters, filters, handleFilterChange, activeFilters }) => {
  const handleMenuClick = (value) => {
    // Add a filter to the top filters
    handleFilterChange([
      ...activeFilters,
      {
        id: Date.now(),
        column: "stato",
        columnName: "Stato",
        value: value,
        displayValue: value,
        operator: "equalTo",
        logicalOperator: "AND"
      }
    ]);
    
    // Close the dropdown
    confirm();
  };

  return (
    <div style={{ padding: 8 }}>
      <Menu>
        {filters.map(filter => (
          <Menu.Item key={filter.value} onClick={() => handleMenuClick(filter.value)}>
            {filter.text}
          </Menu.Item>
        ))}
      </Menu>
    </div>
  );
};

const OrgsV2 = (props) => {
  const location = useLocation();
  const history = useHistory();
  const queryParams = queryString.parse(location.search);
  const activeFilter = queryParams.filter;
  const { isProd } = useAppContext();
  const env = isProd ? "prod" : "dev";

  // State management
  const [loading, setLoading] = useState(true);
  const [orgs, setOrgs] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [totalOrgsCount, setTotalOrgsCount] = useState(0);
  const [filteredOrgsCount, setFilteredOrgsCount] = useState(0);

  // Add flag to prevent duplicate calls
  const isInitialMount = useRef(true);

  const { getTokenSilently } = useAuth0();
  const graphqlUrl = `${config.baseUrl[env]}/graphql?raw=true`;

  // Use the dynamic filter hook instead of combined filter
  const { 
    searchValue, 
    setSearchValue,
    activeFilters,
    fetchFilteredData,
    handleSearchChange, 
    handleFilterChange,
    clearAll
  } = useDynamicFilter(
    setLoading,
    setOrgs,
    setPagination,
    setFilteredOrgsCount
  );

  // Use the column search props
  const { getColumnSearchProps } = useColumnSearch();

  // For backwards compatibility - keep this for legacy URL handling
  const applyActiveClientsFilter = useActiveClientsFilter(
    setPagination,
    setLoading,
    setOrgs,
    setFilteredOrgsCount
  );

  // Simplified reset filters function that uses the clearAll
  const resetFilters = (executeQuery = true) => {
    // Reset pagination to page 1 when filters are reset
    setPagination(prev => ({
      ...prev,
      current: 1
    }));
    
    if (executeQuery) {
      clearAll();
    } else {
      // Just clear the filters in state without triggering a query
      setSearchValue("");
      // Pass skipQuery=true to prevent triggering a query
      handleFilterChange([], true);
    }
  };

  // Fetch total count once on component mount
  const fetchTotalCount = useCallback(async () => {
    try {
      const token = await getTokenSilently();

      const response = await axios({
        method: "post",
        url: graphqlUrl,
        data: {
          query: countOrgsQuery,
          variables: {
            searchTerm: null,
          },
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data?.organizzaziones) {
        const count = response.data.organizzaziones.count;
        setTotalOrgsCount(count);
        // Always update pagination total with the count
        // This ensures pagination shows the correct number of pages
        setPagination((prev) => ({
          ...prev,
          total: count,
        }));
      }
    } catch (error) {
      console.error("Error fetching total count:", error);
    }
  }, [getTokenSilently, graphqlUrl]);

  // Handle pagination change
  const handleTableChange = (newPagination, filters, sorter) => {
    const currentPage = newPagination.current;
    const pageSize = newPagination.pageSize;

    console.log("Table change:", {
      currentPage,
      pageSize,
      total: newPagination.total,
      sorter
    });

    // Update pagination state first
    setPagination((prev) => ({
      ...prev,
      current: currentPage,
      pageSize: pageSize,
      // Ensure total is preserved and consistent with the data source
      total: filteredOrgsCount > 0 ? filteredOrgsCount : totalOrgsCount,
    }));

    // Use the dynamic filter with pagination and sorting params
    fetchFilteredData({
      pageSize: pageSize,
      currentPage: currentPage,
      sortField: sorter.field,
      sortOrder: sorter.order
    });

    // Check if any columns are being filtered
    const hasActiveFilters = Object.values(filters).some(
      (f) => f && f.length > 0
    );

    // If filters are active, count the rows that match the filters
    if (hasActiveFilters) {
      // This only works for client-side filtering
      // For server-side filtering, you'd need to update during the fetch response

      // We can use the table's current data - Column filters are applied automatically
      // by the Table component, so we just need to update the badge count
      setTimeout(() => {
        // Use setTimeout to wait for the table to apply filters internally
        // Get the actual number of rows displayed in the filtered table
        const filteredCount = document.querySelectorAll(
          ".ant-table-tbody tr:not(.ant-table-placeholder)"
        ).length;
        setFilteredOrgsCount(filteredCount);

        // Update pagination total to match filtered count
        setPagination((prev) => ({
          ...prev,
          total: filteredCount,
        }));
      }, 0);
    } else if (filteredOrgsCount > 0 && !hasActiveFilters && !searchValue) {
      // If filters were removed and there's no active search, reset filtered count
      setFilteredOrgsCount(0);

      // Reset pagination total to total count
      setPagination((prev) => ({
        ...prev,
        total: totalOrgsCount,
      }));
    }
  };

  // Load total count on initial render only
  useEffect(() => {
    fetchTotalCount();
  }, [fetchTotalCount]);

  // Fetch data when component mounts or when location changes
  useEffect(() => {
    const params = queryString.parse(location.search);
    const searchTerm = params.search;
    const currentSearch = location.search;
    
    // Skip if the change came from a filter operation with skipQuery flag
    if (location.state && location.state.skipQuery) {
      return;
    }

    // Skip the initial render or duplicate searches
    if (isInitialMount.current) {
      isInitialMount.current = false;

      if (searchTerm) {
        setSearchValue(searchTerm);
        fetchFilteredData({ searchTerm });
      } else if (activeFilter === "active") {
        // For backward compatibility, still use the legacy filter
        applyActiveClientsFilter(pagination.pageSize, pagination.current);
      } else if (params.filters) {
        // If there are filters in the URL but no search term, just fetch with filters
        try {
          const parsedFilters = JSON.parse(decodeURIComponent(params.filters));
          if (Array.isArray(parsedFilters) && parsedFilters.length > 0) {
            fetchFilteredData({ filters: parsedFilters });
          }
        } catch (error) {
          console.error('Error parsing filters from URL:', error);
          fetchFilteredData({ force: true });
        }
      } else {
        // Use the dynamic filter with no search/filters
        fetchFilteredData({ force: true });
      }

      return;
    }

    // Avoid reprocessing the same search
    if (currentSearch === location.search) {
      return;
    }

    if (searchTerm) {
      setSearchValue(searchTerm);
      fetchFilteredData({ searchTerm });
    } else if (activeFilter === "active") {
      // For backward compatibility
      applyActiveClientsFilter(pagination.pageSize, pagination.current);
    } else if (params.filters) {
      // If there are filters in the URL but no search term, just fetch with filters
      try {
        const parsedFilters = JSON.parse(decodeURIComponent(params.filters));
        if (Array.isArray(parsedFilters) && parsedFilters.length > 0) {
          fetchFilteredData({ filters: parsedFilters });
        }
      } catch (error) {
        console.error('Error parsing filters from URL:', error);
        fetchFilteredData({ force: true });
      }
    } else {
      // Use the dynamic filter with no search/filters
      if (!loading) {
        fetchFilteredData({ force: true });
      }
    }
  }, [
    location.search,
    fetchFilteredData,
    activeFilter,
    applyActiveClientsFilter,
    pagination.pageSize,
    pagination.current,
    loading,
    setSearchValue,
  ]);

  // Update search in URL when search value changes
  useEffect(() => {
    const currentParams = queryString.parse(location.search);
    const newParams = {
      ...currentParams,
      search: searchValue || undefined
    };

    // Remove undefined values
    Object.keys(newParams).forEach(key => {
      if (newParams[key] === undefined) {
        delete newParams[key];
      }
    });

    const newSearch = queryString.stringify(newParams);
    const newUrl = `${location.pathname}${newSearch ? `?${newSearch}` : ''}`;
    
    // Update URL without adding to browser history
    if (newUrl !== `${location.pathname}${location.search}`) {
      history.replace(newUrl);
    }
  }, [searchValue, location.pathname, location.search, history]);

  // Don't re-fetch when pageSize changes - let the table handle it
  // This was causing an infinite loop in the original code

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  // Table columns definition
  const columns = [
    {
      title: "ID",
      dataIndex: "objectId",
      key: "objectId",
      ...getColumnSearchProps("objectId"),
      render: (orgId) => {
        return <Link to={`/orgs/${orgId}`}>{orgId}</Link>;
      },
    },
    {
      title: "Nome Azienda",
      dataIndex: "nome",
      key: "nome",
      render: (nome, record) => {
        return <Link to={`/orgs/${record.objectId}`}>{decodeStr(nome)}</Link>;
      },
      sorter: true, // Enable sorting without providing compare function
    },
    {
      title: "Istanza",
      key: "tipo_istanza", 
      dataIndex: "tipo_istanza",
      // Custom filter dropdown that adds a filter to the top filters
      filterDropdown: (props) => (
        <IstanzaFilterDropdown 
          {...props} 
          filters={orgs.length > 0 
            ? orgs
                .map((item) => item.tipo_istanza)
                .filter(Boolean)
                .filter(onlyUnique)
                .sort()
                .map((value) => ({
                  text: value,
                  value,
                }))
            : [ // Default values if orgs is empty
                { text: 'free', value: 'free' },
                { text: 'demo', value: 'demo' },
                { text: 'production', value: 'production' },
              ]
          }
          handleFilterChange={handleFilterChange}
          activeFilters={activeFilters}
        />
      ),
      // Keep the filter icon visible
      filterIcon: () => {
        // Check if there's an active filter for tipo_istanza
        const hasActiveFilter = activeFilters.some(filter => filter.column === "tipo_istanza");
        return <FilterOutlined style={{ color: hasActiveFilter ? '#1890ff' : undefined }} />;
      },
      // Remove the onFilter property as we're handling filtering through the top filters
      render: (tipoIstanza = "", record) => {
        if (!tipoIstanza) {
          return null;
        }

        const colors = {
          free: "blue",
          demo: "orange",
          production: "green",
        };

        return (
          <Tag color={colors[tipoIstanza]}>{tipoIstanza.toUpperCase()}</Tag>
        );
      },
    },
    {
      title: "Stato",
      key: "stato",
      dataIndex: "stato",
      // Custom filter dropdown that adds a filter to the top filters
      filterDropdown: (props) => (
        <StatoFilterDropdown 
          {...props} 
          filters={[
            { text: 'attiva', value: 'attiva' },
            { text: 'disattiva', value: 'disattiva' }
          ]}
          handleFilterChange={handleFilterChange}
          activeFilters={activeFilters}
        />
      ),
      // Keep the filter icon visible
      filterIcon: () => {
        // Check if there's an active filter for stato
        const hasActiveFilter = activeFilters.some(filter => filter.column === "stato");
        return <FilterOutlined style={{ color: hasActiveFilter ? '#1890ff' : undefined }} />;
      },
      render: (stato) => {
        if (!stato) {
          return null;
        }
        const color = stato === 'attiva' ? 'green' : 'red';
        return <Tag color={color}>{stato.toUpperCase()}</Tag>;
      }
    },
    {
      title: "Links",
      key: "email",
      dataIndex: "email",
      render: (_, record) => {
        return <OrgLinks org={record} />;
      },
    },
  ];

  return (
    <div style={{ margin: "16px 16px" }}>
      <div className="orgs-header">
        <Title level={3} style={{ margin: 0 }}>
          <Space>
            Organizzazioni
            <Badge
              overflowCount={5000}
              count={filteredOrgsCount > 0 ? filteredOrgsCount : totalOrgsCount}
              title={
                filteredOrgsCount > 0
                  ? `Showing ${filteredOrgsCount} of ${totalOrgsCount} organizations`
                  : `Total: ${totalOrgsCount} organizations`
              }
            />
          </Space>
        </Title>
        <Space className="orgs-controls" wrap direction="vertical" style={{ width: '100%' }}>
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', flexWrap: 'wrap' }}>
            <FiltersComponent
              resetFilters={resetFilters}
              onFilterChange={handleFilterChange}
              activeFilters={activeFilters}
              showTagsInline={false}
              searchValue={searchValue}
              onSearchChange={handleSearchChange}
              onSearch={(value, _, { source } = {}) => {
                // Reset pagination to page 1 when search is performed
                setPagination(prev => ({
                  ...prev,
                  current: 1
                }));
                
                // If clearing (clicking X button or using clear API), explicitly set empty string
                if (source === 'clear') {
                  setSearchValue("");
                  fetchFilteredData({ searchTerm: "", currentPage: 1 });
                } else {
                  fetchFilteredData({ searchTerm: value, currentPage: 1 });
                }
              }}
            />
          </div>
          {/* Display filter tags on a separate line */}
          <FilterTags 
            activeFilters={activeFilters} 
            onRemoveFilter={(filterId) => handleFilterChange(activeFilters.filter(f => f.id !== filterId))}
          />
        </Space>
      </div>

      <Table
        dataSource={orgs}
        columns={columns}
        loading={loading}
        pagination={{
          ...pagination,
          showSizeChanger: true,
          pageSizeOptions: [
            "10",
            "20",
            "30",
            "50",
            "100",
            "200",
            "500",
            "1000",
            "2000",
            "5000",
          ],
          total: filteredOrgsCount > 0 ? filteredOrgsCount : totalOrgsCount,
          current: pagination.current
        }}
        onChange={handleTableChange}
        scroll={{ x: "max-content" }}
        rowKey="objectId"
      />
    </div>
  );
};

export default OrgsV2;
