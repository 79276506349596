import React from "react";
import { Descriptions, Select, Tag, List } from "antd";
import isFuture from "date-fns/isFuture";
import formatDistance from "date-fns/formatDistance";
import format from "date-fns/format";
import { it } from "date-fns/locale";
import "moment/locale/it";
import FieldDataScadenza from "./FieldDataScadenza";
import FieldAttivaDisattiva from "./FieldAttivaDisattiva";
import FieldActiveStarterPlan from "./FieldActiveStarterPlan";
import FieldSwitch from "./FieldSwitch";
import { decodeStr } from "../../utils";
import ReactJson from "react-json-view";
import { Option } from "antd/lib/mentions";
import { parseISO } from "date-fns";
import { useAuth0 } from "../../react-auth0-spa";
import { useAppContext } from "../../context";
import config from "../../api_config.json";
import updateOrgDetails from "./updateOrgDetails";
import FieldAttivaAF from "./FieldAttivaAF";
import FieldAttivaCentroServizi from "./FieldAttivaCentroServizi";

const TabDettagliOrg = (props) => {
  const { org } = props;
  const { getTokenSilently } = useAuth0();
  const { isProd } = useAppContext();

  const isGiotto =
    org.tipo_licenza === "giotto" &&
    typeof org.data_scadenza_giotto !== "undefined";

  const expiryDate = isGiotto
    ? org.data_scadenza_giotto?.iso
    : org.data_scadenza?.iso;

  const isCurrentlyInDemo = isFuture(parseISO(expiryDate));

  let website;
  if (org.sito) {
    website = org.sito;

    if (!website.startsWith("http")) {
      website = `https://${org.sito}`;
    }
  }

  const handleChangeIstanze = (value) => {
    const fields = [
      {
        key: "tipo_istanza",
        value,
      },
    ];

    updateOrgDetails(getTokenSilently, config, isProd, org.objectId, fields);
  };

  const optionsIstanze = {
    demo: (
      <Option value="demo">
        <Tag color={isCurrentlyInDemo ? "green" : "volcano"}>DEMO</Tag>
      </Option>
    ),
    free: (
      <Option value="free">
        <Tag color="blue">FREE</Tag>
      </Option>
    ),
    production: (
      <Option value="production">
        <Tag color="green">PRODUCTION</Tag>
      </Option>
    ),
  };

  return (
    <>
      <br />
      <Descriptions
        title="Anagrafica"
        layout="vertical"
        column={4}
        size="small"
        bordered
      >
        <Descriptions.Item label="Nome Azienda">
          {decodeStr(org.nome)}
        </Descriptions.Item>
        <Descriptions.Item label="P.IVA">{org.partita_iva}</Descriptions.Item>
        <Descriptions.Item label="Telefono/Cell">
          Telefono 1: {org.telefono1}
          <br />
          Telefono 2: {org.telefono2}
          <br />
          Cellulare 1: {org.cellulare1}
          <br />
          Cellulare 2: {org.cellulare2}
          <br />
        </Descriptions.Item>
        <Descriptions.Item label="Indirizzo">
          {["indirizzo", "cap", "provincia", "citta", "nazione"]
            .filter((key) => org[key])
            .map((key) => (
              <div key={key}>
                {org[key]} <br />
              </div>
            ))}
        </Descriptions.Item>
        <Descriptions.Item label="Email">
          {org.email && <a href={`mailto:${org.email}`}>{org.email}</a>}
          {!org.email && <span>N/A</span>}
        </Descriptions.Item>
        <Descriptions.Item label="Pec">
          {org.pec && <a href={`mailto:${org.pec}`}>{org.pec}</a>}
          {!org.pec && <span>N/A</span>}
        </Descriptions.Item>
        <Descriptions.Item label="Sito">
          {website && (
            <a href={website} target="_blank" rel="noopener noreferrer">
              {org.sito}
            </a>
          )}
          {!website && <span>N/A</span>}
        </Descriptions.Item>
      </Descriptions>
      
      <br />
      <br />
      <Descriptions
        title="Moduli"
        layout="vertical"
        column={1}
        size="small"
        bordered
      >
        <Descriptions.Item label="Moduli Attivi">
          {org.orgModules && org.orgModules.length > 0 ? (
            <List
              size="small"
              bordered
              dataSource={org.orgModules}
              renderItem={item => (
                <List.Item>
                  <Tag color="green">{item}</Tag>
                </List.Item>
              )}
            />
          ) : (
            <span>Nessun modulo attivo</span>
          )}
        </Descriptions.Item>
      </Descriptions>
      
      <br />
      <br />
      <Descriptions
        title="Subscription"
        layout="vertical"
        column={6}
        size="small"
        bordered
      >
        <Descriptions.Item label="Prodotto">
          {org.giotto && <Tag color="geekblue">Giotto</Tag>}
          {org.ofc && <Tag color="purple">Eterno</Tag>}
        </Descriptions.Item>
        <Descriptions.Item label="Istanza">
          <Select
            style={{ width: "100%" }}
            defaultValue={org.tipo_istanza}
            onChange={handleChangeIstanze}
          >
            {Object.values(optionsIstanze)}
          </Select>
        </Descriptions.Item>
        <Descriptions.Item label="Tipo Licenza">
          {org.tipo_licenza}
        </Descriptions.Item>
        <Descriptions.Item label="Codice Piano">
          {org.zoho_plan_code}
        </Descriptions.Item>
        <Descriptions.Item label="Plan">{org.plan}</Descriptions.Item>
        <Descriptions.Item label="Venditore">{org.venditore}</Descriptions.Item>
        <Descriptions.Item label="Data Scadenza Eterno">
          <FieldDataScadenza fieldName="data_scadenza" org={org} />
        </Descriptions.Item>
        <Descriptions.Item label="Data Scadenza Giotto">
          <FieldDataScadenza fieldName="data_scadenza_giotto" org={org} />
        </Descriptions.Item>
        <Descriptions.Item label="Stripe Created At">
          {org.stripe_created_at?.iso &&
            `${format(new Date(org.stripe_created_at.iso), "dd/MM/yyyy")}`}
        </Descriptions.Item>
        <Descriptions.Item label="Last Billing">
          {org.zoho_last_billing?.iso &&
            `${format(new Date(org.zoho_last_billing.iso), "dd/MM/yyyy")}`}
        </Descriptions.Item>
        <Descriptions.Item label="Next Billing">
          {org.zoho_next_billing?.iso &&
            `${format(new Date(org.zoho_next_billing.iso), "dd/MM/yyyy")}`}
        </Descriptions.Item>
        <Descriptions.Item label="Ex-Zoho">
          {org.zoho_created_at && <span>Si</span>}
          {!org.zoho_created_at && <span>No</span>}
        </Descriptions.Item>
      </Descriptions>

      <br />
      <br />
      <Descriptions
        title="Utilizzo"
        layout="vertical"
        column={4}
        size="small"
        bordered
      >
        <Descriptions.Item label="Data Creazione Org">
          {formatDistance(new Date(org.createdAt), new Date(), { locale: it })}
        </Descriptions.Item>
        <Descriptions.Item label="Data Aggiornamento Org">
          {formatDistance(new Date(org.updatedAt), new Date(), { locale: it })}
        </Descriptions.Item>
        <Descriptions.Item label="Servizi Utilizzati">
          Percentuale usati: {org.percent_services_used}%
          <br />
          Limite Servizi: {org.limite_servizi}
          <br />
          Servizi di quest'anno: {org.total_services_current_billing_period}
          <br />
        </Descriptions.Item>
        <Descriptions.Item label="Spazio Utilizzato">
          Spazio: {org.spazio}
          <br />
          Spazio occupato: {org.spazio_occupato}
          <br />
          Spazio disponibile: {org.spazio_disponibile}
          <br />
          Spazio Allegati: {org.spazio_allegati}
          <br />
        </Descriptions.Item>
      </Descriptions>

      <br />
      <br />
      <Descriptions title="Operazioni" bordered>
        <Descriptions.Item label="Org Attiva" span={2}>
          <FieldAttivaDisattiva org={org} />
        </Descriptions.Item>
        <Descriptions.Item label="Fatturazione Attiva" span={2}>
          <FieldSwitch org={org} property="fatturazione_attiva" />
        </Descriptions.Item>
        <Descriptions.Item label="Fatturazione Passiva" span={2}>
          <FieldSwitch org={org} property="fatturazione_passiva" />
        </Descriptions.Item>
        <Descriptions.Item label="Fattura Ad-Hoc" span={2}>
          <FieldSwitch org={org} property="fattura_adhoc" />
        </Descriptions.Item>
        <Descriptions.Item label="Preventivo Ad-Hoc" span={2}>
          <FieldSwitch org={org} property="preventivo_adhoc" />
        </Descriptions.Item>
        <Descriptions.Item label="Ordine Ad-Hoc" span={2}>
          <FieldSwitch org={org} property="ordine_adhoc" />
        </Descriptions.Item>
        <Descriptions.Item label="Demo OFC Already Done" span={2}>
          <FieldSwitch org={org} property="demo_ofc_already_done" />
        </Descriptions.Item>
        <Descriptions.Item label="Eterno" span={2}>
          <FieldSwitch org={org} property="ofc" />
        </Descriptions.Item>
        <Descriptions.Item label="Giotto" span={2}>
          <FieldSwitch org={org} property="giotto" />
        </Descriptions.Item>
        <Descriptions.Item label="Fornitore Centro Prenotazioni" span={2}>
          {/* <FieldSwitch org={org} property="fornitore_centro_prenotazioni" /> */}
          <FieldAttivaCentroServizi org={org} />
        </Descriptions.Item>
        <Descriptions.Item label="Utilizzatore Prestazioni" span={2}>
          <FieldSwitch org={org} property="utilizzatore_prestazioni" />
        </Descriptions.Item>
        <Descriptions.Item label="Integrazione Annunci Funebri" span={2}>
          {/* <FieldSwitch org={org} property="integrazione_annunci_funebri" /> */}
          <FieldAttivaAF org={org} />
        </Descriptions.Item>
        <Descriptions.Item label="Attiva PIANO PARTNER" span={2}>
          <FieldActiveStarterPlan org={org} />
        </Descriptions.Item>
        <Descriptions.Item label="Marketplace" span={2}>
          <FieldSwitch org={org} property="marketplace" />
        </Descriptions.Item>
      </Descriptions>

      <br />
      <br />
      <h3>JSON</h3>
      <ReactJson src={org} name={null} />
    </>
  );
};

export default TabDettagliOrg;
