import React, { useState } from "react";
import { Typography, Divider, Input, Form, Card, Button, Space, Select, InputNumber, Tag, Row, Col, message, Descriptions, Tooltip, Switch } from "antd";
import config from "../../api_config.json";
import EdgeResizer from "./EdgeResizer";
import { useAppContext } from "../../context";
import { EXAMPLES } from "./examples";

const { Title, Text } = Typography;
const { Option } = Select;

const BLEND_MODES = [
  { value: 'over', label: 'Over' },
  { value: 'clear', label: 'Clear' },
  { value: 'source', label: 'Source' },
  { value: 'in', label: 'In' },
  { value: 'out', label: 'Out' },
  { value: 'atop', label: 'Atop' },
  { value: 'dest', label: 'Destination' },
  { value: 'dest-over', label: 'Destination Over' },
  { value: 'dest-in', label: 'Destination In' },
  { value: 'dest-out', label: 'Destination Out' },
  { value: 'dest-atop', label: 'Destination Atop' },
  { value: 'xor', label: 'XOR' },
  { value: 'add', label: 'Add' },
  { value: 'saturate', label: 'Saturate' },
  { value: 'multiply', label: 'Multiply' },
  { value: 'screen', label: 'Screen' },
  { value: 'overlay', label: 'Overlay' },
  { value: 'darken', label: 'Darken' },
  { value: 'lighten', label: 'Lighten' },
  { value: 'colour-dodge', label: 'Colour Dodge' },
  { value: 'color-dodge', label: 'Color Dodge' },
  { value: 'colour-burn', label: 'Colour Burn' },
  { value: 'color-burn', label: 'Color Burn' },
  { value: 'hard-light', label: 'Hard Light' },
  { value: 'soft-light', label: 'Soft Light' },
  { value: 'difference', label: 'Difference' },
  { value: 'exclusion', label: 'Exclusion' }
];

const ImageServicePlayground = () => {
  const { isProd } = useAppContext();
  const env = isProd ? "prod" : "dev";
  const [form] = Form.useForm();
  const [, setImageUrl] = useState("");
  const [processedUrl, setProcessedUrl] = useState("");
  const [loadingTime, setLoadingTime] = useState(null);
  const [compositeImages, setCompositeImages] = useState([]);
  const [compositePreview, setCompositePreview] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [imageError, setImageError] = useState(null);
  const [headers, setHeaders] = useState(null);
  const [imageStats, setImageStats] = useState(null);

  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
  };

  const getImageSize = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      return blob.size;
    } catch (error) {
      console.error('Error fetching image size:', error);
      return 0;
    }
  };

  const fetchImageHeaders = async (url) => {
    try {
      const response = await fetch(url, { method: 'HEAD' });
      const headerEntries = Array.from(response.headers.entries());
      const normalizedHeaders = headerEntries.reduce((acc, [key, value]) => {
        const normalizedKey = key.toLowerCase();
        // Include all relevant headers
        if (normalizedKey.includes('content-type') || 
            normalizedKey.includes('cache-control') ||
            normalizedKey === 'age' ||
            normalizedKey.includes('x-cache') ||
            normalizedKey === 'location') {
          acc[key] = value;
        }
        // Add CORS headers
        if (normalizedKey.includes('access-control-')) {
          acc[key] = value;
        }
        // Add CloudFront specific headers
        if (normalizedKey.includes('x-amz-') || 
            normalizedKey.includes('x-cache') ||
            normalizedKey.includes('via')) {
          acc[key] = value;
        }
        return acc;
      }, {});

      // Group headers by category for better organization
      const headers = {
        'Status Code': response.status,
        // Basic headers
        'Content-Type': normalizedHeaders['content-type'] || response.headers.get('Content-Type'),
        'Content-Length': normalizedHeaders['content-length'] || response.headers.get('Content-Length'),
        'Cache-Control': normalizedHeaders['cache-control'] || response.headers.get('Cache-Control'),
        
        // CloudFront headers
        'X-Cache': normalizedHeaders['x-cache'] || response.headers.get('X-Cache'),
        'X-Cache-Hits': normalizedHeaders['x-cache-hits'] || response.headers.get('X-Cache-Hits'),
        'Via': normalizedHeaders['via'] || response.headers.get('Via'),
        'Age': normalizedHeaders['age'] || response.headers.get('Age'),
        
        // CORS headers
        'Access-Control-Allow-Origin': normalizedHeaders['access-control-allow-origin'] || response.headers.get('Access-Control-Allow-Origin'),
        'Access-Control-Allow-Methods': normalizedHeaders['access-control-allow-methods'] || response.headers.get('Access-Control-Allow-Methods'),
        'Access-Control-Allow-Headers': normalizedHeaders['access-control-allow-headers'] || response.headers.get('Access-Control-Allow-Headers'),
        
        // Other headers
        'Location': normalizedHeaders['location'] || response.headers.get('Location')
      };

      // Clean up undefined values
      Object.keys(headers).forEach(key => {
        if (headers[key] === undefined || headers[key] === null) {
          delete headers[key];
        }
      });

      console.log('Processed headers:', headers);
      setHeaders(headers);
    } catch (error) {
      console.error('Error fetching headers:', error);
      setHeaders(null);
    }
  };

  const generateImage = () => {
    // Reset all states before starting new request
    setIsLoading(true);
    setImageError(null);
    setHeaders(null);
    setLoadingTime(null);
    setProcessedUrl("");
    setImageUrl("");
    setCompositePreview("");
    setImageStats(null);
    setStartTime(performance.now());
    
    form.validateFields().then(values => {
      try {
        const inputUrl = `${config.files[env]}/${values.imagePath}`;
        console.log('inputUrl', inputUrl);
        const edgeResizer = new EdgeResizer(config.files[env], inputUrl);

        // Apply watermark if enabled
        if (values.watermark) {
          edgeResizer.withWatermark();
        }

        // Apply width/height if specified
        if (values.width) {
          edgeResizer.scaleToWidth(values.width);
        }

        if (values.height) {
          edgeResizer.scaleToHeight(values.height);
        }

        // Apply resize if both width and height are specified
        if (values.width && values.height && values.resizeType) {
          edgeResizer.resize(values.width, values.height, values.resizeType);
        }

        // Apply extend if specified
        if (values.extend) {
          edgeResizer.extend(values.extend);
        }

        // Apply blur if specified
        if (values.blur) {
          edgeResizer.blur(values.blur);
        }

        // Apply composites
        compositeImages.forEach(composite => {
          edgeResizer.composite(composite.url, composite.blendMode);
        });

        const finalUrl = edgeResizer.toString();
        console.log('finalUrl', finalUrl);
        setImageUrl(finalUrl);
        setProcessedUrl(finalUrl);
        fetchImageHeaders(finalUrl);

        // Update composite preview if there are composites
        if (compositeImages.length > 0) {
          const params = new URL(finalUrl).searchParams;
          const compositeParam = params.get('composite');
          if (compositeParam) {
            const decodedComposite = Buffer.from(compositeParam, 'base64').toString();
            setCompositePreview(JSON.stringify(JSON.parse(decodedComposite), null, 2));
          }
        } else {
          setCompositePreview("");
        }

      } catch (error) {
        console.error('Error processing image:', error);
        message.error('Failed to process image: ' + error.message);
        setImageError('Failed to process image');
        setIsLoading(false);
      }
    }).catch(error => {
      console.error('Form validation error:', error);
      message.error('Please check your input values');
      setIsLoading(false);
    });
  };

  const handleImageLoad = async (e) => {
    const endTime = performance.now();
    setLoadingTime(endTime - startTime);
    setIsLoading(false);
    setImageError(null);

    // Calculate image stats
    const img = e.target;
    let fileSize = headers?.['Content-Length'] ? parseInt(headers['Content-Length']) : 0;

    // If Content-Length is missing, fetch the image to get its size
    if (!fileSize) {
      fileSize = await getImageSize(processedUrl);
    }
    
    setImageStats({
      width: img.naturalWidth,
      height: img.naturalHeight,
      size: formatFileSize(parseInt(fileSize) || 0)
    });
  };

  const handleImageError = () => {
    setIsLoading(false);
    setImageError('Failed to load image');
    message.error('Failed to load image');
  };

  const addCompositeImage = () => {
    form.validateFields(['compositeUrl', 'blendMode']).then(values => {
      setCompositeImages([...compositeImages, {
        url: values.compositeUrl,
        blendMode: values.blendMode
      }]);
      form.setFieldsValue({ compositeUrl: '', blendMode: undefined });
    });
  };

  const removeCompositeImage = (index) => {
    const newComposites = [...compositeImages];
    newComposites.splice(index, 1);
    setCompositeImages(newComposites);
  };

  const loadExample = (example) => {
    // Reset form and states
    form.resetFields();
    setCompositeImages([]);
    setCompositePreview("");
    setImageStats(null);
    setHeaders(null);
    setProcessedUrl("");
    setImageUrl("");
    setLoadingTime(null);
    setImageError(null);

    // Set form values from example
    const { config } = example;
    form.setFieldsValue({
      serviceHost: form.getFieldValue('serviceHost'), // Keep the current service host
      imagePath: config.imagePath,
      width: config.width,
      height: config.height,
      resizeType: config.resizeType,
      extend: config.extend,
      blur: config.blur,
      watermark: config.watermark || false
    });

    // Set composites if they exist
    if (config.composites?.length > 0) {
      setCompositeImages(config.composites);
    }
  };

  return (
    <div style={{ margin: "16px 16px" }}>
      <Title level={3} style={{ marginBottom: "20px" }}>
        Image Service Playground
      </Title>
      <Divider />

      <Row gutter={16}>
        <Col span={12}>
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              serviceHost: config.files[env]
            }}
          >
            <Space direction="vertical" style={{ width: '100%' }} size="large">
              <Card size="small" title="Examples">
                <Space wrap>
                  {EXAMPLES.map((example, index) => (
                    <Tooltip key={index} title={example.description}>
                      <Button onClick={() => loadExample(example)}>
                        {example.name}
                      </Button>
                    </Tooltip>
                  ))}
                </Space>
              </Card>

              <Card title="Image URL Configuration">
                <Form.Item label="Service Host" name="serviceHost">
                  <Input disabled />
                </Form.Item>
                <Form.Item 
                  label="Image Path" 
                  name="imagePath"
                  rules={[{ required: true, message: 'Please input an image path!' }]}
                >
                  <Input placeholder="Enter image path" />
                </Form.Item>
              </Card>

              <Card title="Image Processing Options">
                <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="Width" name="width">
                        <InputNumber min={1} style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="Height" name="height">
                        <InputNumber min={1} style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item label="Resize Type" name="resizeType">
                        <Select>
                          <Option value="cover">Cover</Option>
                          <Option value="contain">Contain</Option>
                          <Option value="fill">Fill</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item 
                        label="Extend" 
                        name="extend"
                        tooltip="Enlarge the image by adding padding (in pixels)"
                      >
                        <InputNumber min={0} style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Blur" name="blur">
                        <InputNumber min={0} max={100} style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item 
                        label="Watermark"
                        name="watermark" 
                        valuePropName="checked"
                        tooltip="Add watermark to the image"
                      >
                        <Switch checkedChildren="✓" unCheckedChildren="✕" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Space>
              </Card>

              <Card title="Composite Images">
                <Space direction="vertical" style={{ width: '100%' }}>
                  <Row gutter={16} style={{ width: '100%' }}>
                    <Col span={14}>
                      <Form.Item 
                        name="compositeUrl" 
                        style={{ marginBottom: 0 }}
                      >
                        <Input placeholder="Composite image URL" style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item 
                        name="blendMode" 
                        style={{ marginBottom: 0 }}
                        initialValue="over"
                      >
                        <Select
                          placeholder="Blend Mode"
                          style={{ width: '100%' }}
                          showSearch
                          optionFilterProp="children"
                        >
                          {BLEND_MODES.map(mode => (
                            <Option key={mode.value} value={mode.value}>
                              {mode.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Button type="primary" onClick={addCompositeImage} block>
                        Add
                      </Button>
                    </Col>
                  </Row>

                  <div style={{ marginTop: '8px' }}>
                    {compositeImages.map((composite, index) => {
                      const blendMode = BLEND_MODES.find(mode => mode.value === composite.blendMode);
                      return (
                        <Tag 
                          key={index} 
                          closable 
                          onClose={() => removeCompositeImage(index)}
                          style={{ margin: '4px' }}
                        >
                          {`${composite.url} (${blendMode?.label || composite.blendMode})`}
                        </Tag>
                      );
                    })}
                  </div>

                  {compositePreview && (
                    <Card size="small" title="Composite Parameter Preview">
                      <pre>{compositePreview}</pre>
                    </Card>
                  )}
                </Space>
              </Card>

              <Button type="primary" onClick={generateImage} size="large" block>
                Generate Image
              </Button>
            </Space>
          </Form>
        </Col>

        <Col span={12} style={{ position: 'sticky', top: 16 }}>
          {processedUrl ? (
            <Space direction="vertical" style={{ width: '100%' }} size="large">
              <Card title="Preview" style={{ position: 'sticky', top: 16 }}>
                <Space direction="vertical" style={{ width: '100%' }} size="middle">
                  <Card size="small" className="url-preview">
                    <Space direction="vertical" style={{ width: '100%' }}>
                      <Text type="secondary">Generated URL</Text>
                      <div style={{ 
                        background: '#f5f5f5', 
                        padding: '8px 12px', 
                        borderRadius: '4px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}>
                        <Text style={{ 
                          wordBreak: 'break-all',
                          flex: 1,
                          marginRight: 8
                        }}>
                          {processedUrl}
                        </Text>
                        <Text copyable={{ text: processedUrl }} style={{ flexShrink: 0 }} />
                      </div>
                    </Space>
                  </Card>

                  {headers && (
                    <Card size="small" title="Response Headers">
                      <Space direction="vertical" style={{ width: '100%' }} size="small">
                        {/* Status and Basic Info */}
                        <Descriptions column={1} size="small" title={<Text type="secondary">Basic Information</Text>}>
                          {['Status Code', 'Content-Type', 'Content-Length', 'Cache-Control'].map(key => (
                            headers[key] && <Descriptions.Item key={key} label={key}>{headers[key]}</Descriptions.Item>
                          ))}
                        </Descriptions>

                        {/* CloudFront Info */}
                        {(headers['X-Cache'] || headers['X-Cache-Hits'] || headers['Via'] || headers['Age']) && (
                          <Descriptions column={1} size="small" title={<Text type="secondary">CloudFront Information</Text>}>
                            {['X-Cache', 'X-Cache-Hits', 'Via', 'Age'].map(key => (
                              headers[key] && <Descriptions.Item key={key} label={key}>{headers[key]}</Descriptions.Item>
                            ))}
                          </Descriptions>
                        )}

                        {/* CORS Headers */}
                        {(headers['Access-Control-Allow-Origin'] || headers['Access-Control-Allow-Methods'] || headers['Access-Control-Allow-Headers']) && (
                          <Descriptions column={1} size="small" title={<Text type="secondary">CORS Headers</Text>}>
                            {['Access-Control-Allow-Origin', 'Access-Control-Allow-Methods', 'Access-Control-Allow-Headers'].map(key => (
                              headers[key] && <Descriptions.Item key={key} label={key}>{headers[key]}</Descriptions.Item>
                            ))}
                          </Descriptions>
                        )}
                      </Space>
                    </Card>
                  )}

                  {imageStats && (
                    <Card size="small" title="Image Information">
                      <Descriptions column={1} size="small">
                        <Descriptions.Item label="Dimensions">{imageStats.width} × {imageStats.height}px</Descriptions.Item>
                        <Descriptions.Item label="File Size">{imageStats.size}</Descriptions.Item>
                      </Descriptions>
                    </Card>
                  )}

                  {loadingTime && !imageError && (
                    <div style={{ textAlign: 'right' }}>
                      <Text type="secondary">
                        Load time: {loadingTime.toFixed(2)}ms
                      </Text>
                    </div>
                  )}

                  {isLoading && (
                    <div style={{ textAlign: 'center', padding: '20px 0' }}>
                      <Text type="secondary">Loading image...</Text>
                    </div>
                  )}
                  
                  {imageError ? (
                    <div style={{ textAlign: 'center', padding: '20px 0' }}>
                      <Text type="danger">{imageError}</Text>
                    </div>
                  ) : (
                    <div style={{ 
                      border: '1px solid #f0f0f0', 
                      borderRadius: '4px',
                      padding: '8px',
                      background: '#fafafa'
                    }}>
                      <img 
                        src={processedUrl} 
                        alt="Processed" 
                        style={{ 
                          maxWidth: '100%', 
                          height: 'auto',
                          display: isLoading ? 'none' : 'block',
                          borderRadius: '2px'
                        }} 
                        onLoad={handleImageLoad}
                        onError={handleImageError}
                      />
                    </div>
                  )}
                </Space>
              </Card>
            </Space>
          ) : (
            <Card>
              <div style={{ textAlign: 'center', padding: '40px 0' }}>
                <Text type="secondary">Preview will appear here after generating the image</Text>
              </div>
            </Card>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ImageServicePlayground; 