import React, { useState, useEffect } from "react";
import { Table, Tag, Space, message } from "antd";
import { useAuth0 } from "../../../react-auth0-spa";
import { useAppContext } from "../../../context";
import config from "../../../api_config.json";
import { decodeStr } from "../../../utils";
import ReactJson from "react-json-view";
import ParseLogo from "../../OrgDetails/images/parse.png";

const OrgUsers = ({ orgId }) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const { getTokenSilently } = useAuth0();
  const { isProd } = useAppContext();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setLoading(true);
        const token = await getTokenSilently();

        // GraphQL query to fetch users filtered by org_pointer
        const query = `
          query GetOrgUsers($orgId: ID!) {
            users(first: 1000, order: first_name_ASC, where: {org_pointer: {have: {objectId: {equalTo: $orgId}}}}) {
              edges {
                node {
                  objectId
                  first_name
                  last_name
                  telefono
                  ruolo
                  email
                  user_status
                }
              }
            }
          }
        `;

        const response = await fetch(
          `${config.baseUrl[isProd ? "prod" : "dev"]}/graphql?raw=true`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              query,
              variables: { orgId },
            }),
          }
        );

        const result = await response.json();

        if (result.errors) {
          throw new Error(result.errors[0].message);
        }

        // Transform the edges/node structure to a flat array
        const usersData = result.users.edges.map((edge) => edge.node);
        setUsers(usersData || []);
      } catch (error) {
        console.error("Error fetching users:", error);
        message.error("Errore nel caricamento degli utenti");
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [orgId, getTokenSilently, isProd]);

  const columns = [
    {
      title: "ID",
      dataIndex: "objectId",
      key: "objectId",
    },
    {
      title: "Nome",
      dataIndex: "first_name",
      key: "first_name",
      render: (text) => decodeStr(text || ""),
    },
    {
      title: "Cognome",
      dataIndex: "last_name",
      key: "last_name",
      render: (text) => decodeStr(text || ""),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => text || "-",
    },
    {
      title: "Telefono",
      dataIndex: "telefono",
      key: "telefono",
      render: (text) => text || "-",
    },
    {
      title: "Ruolo",
      dataIndex: "ruolo", 
      key: "ruolo",
      render: (ruolo) => {
        if (!ruolo) return <Tag color="default">-</Tag>;

        const colors = {
          Amministratore: "purple",
          Collaboratore: "geekblue",
        };

        return <Tag color={colors[ruolo] || "blue"}>{ruolo}</Tag>;
      },
    },
    {
      title: "Stato",
      dataIndex: "user_status", 
      key: "user_status",
      render: (userStatus) => {
        return (
          <Tag color={userStatus ? "green" : "volcano"}>
            {userStatus ? "ATTIVO" : "DISATTIVO"}
          </Tag>
        );
      },
    },
    {
      title: "Links",
      dataIndex: "objectId",
      key: "links",
      render: (userId) => {
        return (
          <Space direction="horizontal">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${
                config.parseDashboard[isProd ? "prod" : "dev"]
              }/browser/_User?filters=[{"field":"objectId","constraint":"eq","compareTo":"${userId}"}]`}
            >
              <img src={ParseLogo} width={20} alt="logo" />
            </a>
          </Space>
        );
      },
    },
  ];

  return (
    <div className="org-users-container">
      <Table
        dataSource={users}
        columns={columns}
        rowKey="objectId"
        loading={loading}
        pagination={false}
        expandable={{
          expandedRowRender: (record) => <ReactJson src={record} name={null} />,
          rowExpandable: (record) => record,
        }}
      />
    </div>
  );
};

export default OrgUsers;
