export const EXAMPLES = [
  {
    name: "Basic Resize",
    description: "Simple image resize to 800x600",
    config: {
      imagePath: "PNgeKjwe16_allegati_cefa5542-d165-4c77-8bd9-3b7cf2645ad4.png",
      width: 400,
      height: 400,
      resizeType: "cover",
    },
  },
  {
    name: "Blurbg",
    description: "Showcase the blur and composite features",
    config: {
      imagePath: "PNgeKjwe16_allegati_cefa5542-d165-4c77-8bd9-3b7cf2645ad4.png",
      blur: 20,
      composites: [
        {
          url: "https://files.onoranzefunebricloud.com/images/9fb83699c82d2a90543bb4fdb6c60753_e86d2cd3c83f99e556d6d85f5fc5d424.png",
          blendMode: "over",
        },
      ],
    },
  },
  {
    name: "Blurbg 2",
    description: "Showcase the blur and composite features",
    config: {
      imagePath: "PNgeKjwe16_allegati_7d050e17-bf21-4bda-96da-352028ecf020.jpg",
      blur: 20,
      composites: [
        {
          url: "https://files.onoranzefunebricloud.com/2952142288cd56269f05e4e791c08a10_285ad40542c97888be2deb9bda30b7f5.png",
          blendMode: "over",
        },
      ],
    },
  },
  {
    name: "With Watermark",
    description: "Showcase the watermark feature",
    config: {
      imagePath: "f27245c2f7d295046cf3f4ec8617ee23_manifestoorizzontalegesujpg.jpeg",
      watermark: true,
      width: 800
    },
  },
];
