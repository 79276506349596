import React from "react";
import { Card } from "antd";

const OrgCentroPrenotazioni = ({ orgId }) => {
  return (
    <Card title="Centro Prenotazioni" bordered={false}>
      <p>Contenuto per la sezione Centro Prenotazioni da implementare</p>
    </Card>
  );
};

export default OrgCentroPrenotazioni; 