import React, { useEffect } from "react";
import {
  Form,
  Input,
  Switch,
  Select,
  Row,
  Col,
  Divider,
  Tooltip,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

const { TextArea } = Input;

const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};

const defaultValues = {
  name: "",
  label: "",
  description: "",
  ofc_managed: false,
  plans: [],
  products: [],
  module_pointer: null,
};

const FeatureEditForm = ({
  form,
  initialValues,
  isCreating,
  modules,
  plans,
}) => {
  useEffect(() => {
    form.setFieldsValue({
      name: initialValues.name || defaultValues.name,
      label: initialValues.label || defaultValues.label,
      description: initialValues.description || defaultValues.description,
      ofc_managed: initialValues.ofc_managed || defaultValues.ofc_managed,
      plans: initialValues.plans?.map(plan => typeof plan === 'string' ? plan : plan.value) || defaultValues.plans,
      products: initialValues.products?.map(product => typeof product === 'string' ? product : product.value) || defaultValues.products,
      module_pointer: initialValues.module_pointer?.objectId || defaultValues.module_pointer,
    });
  }, [form, initialValues]);

  return (
    <Form
      form={form}
      name="feature_edit"
      layout="horizontal"
    >
      <Row gutter={32}>
        <Col span={12}>
          <Divider orientation="left">Informazioni Base</Divider>
          <Form.Item
            {...layout}
            name="name"
            label="Nome"
            rules={[
              { required: true, message: "Inserisci il nome della feature!" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            {...layout}
            name="label"
            label="Label"
            rules={[
              { required: true, message: "Inserisci la label della feature!" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            {...layout}
            name="description"
            label={
              <span>
                Descrizione{" "}
                <Tooltip title="Descrizione dettagliata della feature">
                  <InfoCircleOutlined style={{ color: "#1890ff" }} />
                </Tooltip>
              </span>
            }
          >
            <TextArea rows={4} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Divider orientation="left">Configurazione</Divider>
          <Form.Item
            {...layout}
            name="ofc_managed"
            label={
              <span>
                OFC Managed{" "}
                <Tooltip title="Indica se la feature è gestita da OFC">
                  <InfoCircleOutlined style={{ color: "#1890ff" }} />
                </Tooltip>
              </span>
            }
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <Form.Item
            {...layout}
            name="plans"
            label="Plans"
          >
            <Select
              mode="multiple"
              allowClear
              placeholder="Seleziona i piani"
              optionFilterProp="children"
              showSearch
            >
              {plans?.map((plan) => (
                <Select.Option key={plan} value={plan}>
                  {plan}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            {...layout}
            name="products"
            label="Products"
          >
            <Select
              mode="multiple"
              allowClear
              placeholder="Seleziona i prodotti"
              optionFilterProp="children"
              showSearch
            >
              <Select.Option key="giotto" value="giotto">
                giotto
              </Select.Option>
              <Select.Option key="eterno" value="eterno">
                eterno
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            {...layout}
            name="module_pointer"
            label="Modulo"
          >
            <Select
              allowClear
              placeholder="Seleziona il modulo"
              optionFilterProp="children"
              showSearch
            >
              {modules?.map((module) => (
                <Select.Option key={module.objectId} value={module.objectId}>
                  {module.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default FeatureEditForm; 