import React, { useState, useEffect, useCallback } from "react";
import {
  Breadcrumb,
  Button,
  Table,
  Input,
  Space,
  Typography,
  Tag,
  Divider,
  Popover,
} from "antd";
import axios from "axios";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useAuth0 } from "../../react-auth0-spa";
import config from "../../api_config.json";
import { useAppContext } from "../../context";
import {
  SearchOutlined,
  EditOutlined,
  CopyOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { formatDistance } from "date-fns";
import { it } from "date-fns/locale";
import FeatureEditModal from "./FeatureEditModal";
import ParseLogo from "../../images/logos/parse.png";

const { Title } = Typography;

const PREVIEW_ACTIONS_COUNT = 5;

const ACTION_COLORS = {
  create: "green",
  delete: "red",
  update: "orange",
  modify: "orange",
  show: "cyan",
  clone: "blue",
  invite: "magenta",
  associate: "gold",
  dissociate: "gold",
  share: "lime",
  send: "purple",
  connect: "purple",
  default: "default",
};

const query = `
  query getFeatures($where: FeaturesWhereInput!, $first: Int!) {
    findFeatures(where: $where, first: $first) {
      edges {
        node {
          objectId
          description
          ofc_managed
          label
          name
          products {
            ... on Element {
              value
            }
          }
          plans {
            ... on Element {
              value
            }
          }
          module_pointer {
            objectId
            name
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`
  .replace(/\r?\n|\r/g, "")
  .replace(/\t/g, " ");

const Features = () => {
  const [loading, setLoading] = useState(true);
  const [features, setFeatures] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const { isProd } = useAppContext();
  const env = isProd ? "prod" : "dev";
  const { getTokenSilently } = useAuth0();
  const graphqlUrl = `${config.baseUrl[env]}/graphql`;
  const parseDashboardUrl = config.parseDashboard[env];
  const [editingFeature, setEditingFeature] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      const token = await getTokenSilently();
      const response = await axios({
        method: "post",
        url: graphqlUrl,
        data: {
          query,
          variables: {
            where: {},
            first: 10000,
          },
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const items = response.data || [];
      const featuresData = items.sort((a, b) => a.name.localeCompare(b.name));

      // Group features by the base name (before the dot)
      const groupedFeatures = featuresData.reduce((acc, feature) => {
        const baseName = feature.name.split(".")[0];
        if (!acc[baseName]) {
          acc[baseName] = {
            key: baseName,
            name: baseName,
            actions: [],
            actionPreviews: new Set(),
          };
        }
        const action = feature.name.split(".").slice(1).join(".");
        acc[baseName].actions.push({
          ...feature,
          key: feature.objectId,
          action,
        });
        acc[baseName].actionPreviews.add(action);
        return acc;
      }, {});

      // Convert to array and add computed properties
      const processedFeatures = Object.values(groupedFeatures).map((group) => ({
        ...group,
        actionCount: group.actions.length,
        actionPreviews: Array.from(group.actionPreviews || []).slice(
          0,
          PREVIEW_ACTIONS_COUNT
        ), // Show 5 actions for preview
        allActions: Array.from(group.actionPreviews || []).join(", "),
      }));

      setFeatures(processedFeatures);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching features:", error);
      setLoading(false);
    }
  }, [getTokenSilently, graphqlUrl]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {}, 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  const getActionColor = (action) => {
    const actionLower = action.toLowerCase();
    return (
      Object.entries(ACTION_COLORS).find(([key]) =>
        actionLower.includes(key)
      )?.[1] || ACTION_COLORS.default
    );
  };

  const columns = [
    {
      title: "Feature Name",
      dataIndex: "name",
      key: "name",
      width: "25%",
      ...getColumnSearchProps("name"),
      sorter: {
        compare: (a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        },
      },
      render: (text, record) => (
        <Space>
          <Tag color="blue">{record.actionCount || 0}</Tag>
          <span>{text}</span>
        </Space>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actionPreviews",
      key: "actionPreviews",
      width: "35%",
      render: (_, record) => {
        const previews = record.actionPreviews || [];
        return (
          <Space wrap>
            {previews.map((action, index) => (
              <Tag key={index} color={getActionColor(action)}>
                {action}
              </Tag>
            ))}
            {(record.actionCount || 0) > PREVIEW_ACTIONS_COUNT && (
              <Tag color="default">
                +{record.actionCount - PREVIEW_ACTIONS_COUNT} more
              </Tag>
            )}
          </Space>
        );
      },
    },
    {
      title: "Modules",
      key: "modules",
      width: "25%",
      render: (_, record) => {
        // Collect all unique modules from all actions
        const allModules = new Set();
        record.actions.forEach((action) => {
          if (action.module_pointer) {
            allModules.add(action.module_pointer);
          }
        });

        const modulesList = Array.from(allModules);

        if (modulesList.length === 0) {
          return "-";
        }

        return (
          <Space>
            {modulesList.length > 0 && (
              <Popover
                content={
                  <div style={{ maxWidth: "400px" }}>
                    {modulesList
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((module) => (
                        <Tag
                          key={module.objectId}
                          color="purple"
                          style={{ margin: "2px" }}
                        >
                          {module.name}
                        </Tag>
                      ))}
                  </div>
                }
                title="Associated Modules"
                trigger="hover"
              >
                <Tag color="purple" style={{ cursor: "pointer" }}>
                  {modulesList.length}{" "}
                  {modulesList.length === 1 ? "module" : "modules"}
                </Tag>
              </Popover>
            )}
          </Space>
        );
      },
    },
  ];

  const expandedColumns = [
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "20%",
      ...getColumnSearchProps("action"),
      render: (action) => <Tag color={getActionColor(action)}>{action}</Tag>,
    },
    {
      title: "Label",
      dataIndex: "label",
      key: "label",
      ...getColumnSearchProps("label"),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      ...getColumnSearchProps("description"),
    },
    {
      title: "OFC Managed",
      dataIndex: "ofc_managed",
      key: "ofc_managed",
      filters: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
      onFilter: (value, record) => record.ofc_managed === value,
      render: (managed) => (
        <Tag color={managed ? "green" : "red"}>{managed ? "Yes" : "No"}</Tag>
      ),
    },
    {
      title: "Products",
      dataIndex: "products",
      key: "products",
      filters: [
        { text: "giotto", value: "giotto" },
        { text: "eterno", value: "eterno" },
      ],
      onFilter: (value, record) =>
        record.products?.some((product) => product.value === value),
      render: (products) => (
        <Space>
          {products?.map((product, index) => (
            <Tag key={index} color={product.value === "giotto" ? "blue" : "purple"}>
              {product.value}
            </Tag>
          ))}
        </Space>
      ),
    },
    {
      title: "Plans",
      dataIndex: "plans",
      key: "plans",
      filters: features
        .flatMap((feature) => feature.actions)
        .flatMap((feature) => feature.plans?.map((plan) => plan.value))
        .filter(Boolean)
        .filter(onlyUnique)
        .map((value) => ({
          text: value,
          value,
        })),
      onFilter: (value, record) =>
        record.plans?.some((plan) => plan.value === value),
      render: (plans) => (
        <Space>
          {plans?.map((plan, index) => (
            <Tag key={index} color="blue">
              {plan.value}
            </Tag>
          ))}
        </Space>
      ),
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) =>
        formatDistance(new Date(date), new Date(), { locale: it }),
      sorter: {
        compare: (a, b) => {
          if (new Date(a.createdAt) < new Date(b.createdAt)) return -1;
          if (new Date(a.createdAt) > new Date(b.createdAt)) return 1;
          return 0;
        },
      },
    },
    {
      title: "Updated",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (date) =>
        formatDistance(new Date(date), new Date(), { locale: it }),
      sorter: {
        compare: (a, b) => {
          if (new Date(a.updatedAt) < new Date(b.updatedAt)) return -1;
          if (new Date(a.updatedAt) > new Date(b.updatedAt)) return 1;
          return 0;
        },
      },
    },
    {
      title: "Module",
      key: "module",
      render: (_, record) => {
        return (
          <Space>
            {record.module_pointer ? (
              <Tag color="purple">{record.module_pointer.name}</Tag>
            ) : (
              "-"
            )}
          </Space>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space onClick={(e) => e.stopPropagation()}>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={(e) => {
              e.stopPropagation();
              handleEdit(record);
            }}
          />
          <Button
            type="link"
            icon={<CopyOutlined />}
            onClick={(e) => {
              e.stopPropagation();
              handleClone(record);
            }}
          />
          <a
            href={`${parseDashboardUrl}/browser/features?filters=[{"field"%3A"objectId","constraint"%3A"eq","compareTo"%3A"${record.objectId}"}]`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => e.stopPropagation()}
          >
            <img
              src={ParseLogo}
              alt="Parse Dashboard"
              style={{
                width: "20px",
                height: "20px",
                objectFit: "contain",
                verticalAlign: "middle",
              }}
            />
          </a>
        </Space>
      ),
    },
  ];

  const expandedRowRender = (record) => {
    return (
      <Table
        columns={expandedColumns}
        dataSource={record.actions}
        pagination={false}
        rowKey="objectId"
        rowClassName="cursor-pointer"
        onRow={(record) => ({
          onClick: () => handleEdit(record),
        })}
      />
    );
  };

  const handleEdit = (record) => {
    setEditingFeature(record);
    setIsModalVisible(true);
  };

  const handleNew = () => {
    setEditingFeature(null);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setEditingFeature(null);
    setIsModalVisible(false);
  };

  const handleSuccess = () => {
    setEditingFeature(null);
    setIsModalVisible(false);
    fetchData();
  };

  const handleClone = (record) => {
    const clonedFeature = {
      ...record,
      name: `${record.name} (Copy)`,
      objectId: null,
    };
    setEditingFeature(clonedFeature);
    setIsModalVisible(true);
  };

  return (
    <div style={{ margin: "16px 16px" }}>
      <div>{loading && <LinearProgress />}</div>
      <Breadcrumb style={{ marginBottom: "20px" }}>
        <Breadcrumb.Item>Configurazioni</Breadcrumb.Item>
        <Breadcrumb.Item>Features</Breadcrumb.Item>
      </Breadcrumb>
      <Divider />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "40px",
        }}
      >
        <Title level={3}>Features</Title>
        <Button type="primary" icon={<PlusOutlined />} onClick={handleNew}>
          Nuova Feature
        </Button>
      </div>
      <Table
        dataSource={features}
        columns={columns}
        loading={loading}
        size="small"
        pagination={false}
        expandable={{
          expandedRowRender,
          defaultExpandedRowKeys: [],
          expandRowByClick: true,
        }}
        rowKey="name"
        onRow={(record) => ({
          style: { cursor: 'pointer' }
        })}
      />
      <FeatureEditModal
        visible={isModalVisible}
        feature={editingFeature}
        onCancel={handleCancel}
        onSuccess={handleSuccess}
      />
    </div>
  );
};

export default Features;
