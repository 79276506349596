import React from "react";
import { Card } from "antd";

const OrgDocumenti = ({ orgId }) => {
  return (
    <Card title="Documenti" bordered={false}>
      <p>Contenuto per la sezione Documenti da implementare</p>
    </Card>
  );
};

export default OrgDocumenti; 