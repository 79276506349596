import React from "react";

const OrgUsage = ({ orgId }) => {
  return (
    <iframe
      title="Usage"
      src={`https://analytics.zoho.eu/open-view/10370000003745715/60420cf44e0e76883263f0a0de5107ce?ZOHO_CRITERIA=%22Organizzazione%22.%22objectId%22%3D'${orgId}'`}
      style={{
        height: "100vh",
        width: "100%",
        border: "none",
      }}
      height="100%"
      width="100%"
    />
  );
};

export default OrgUsage; 