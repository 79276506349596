const queryOrgDetails = `
query GetOrganizzazione($id: ID!) {
  organizzazione(id: $id) {
    objectId
    nome
    stato
    tipo_istanza
    plan
    email
    telefono1
    telefono2
    cellulare1
    cellulare2
    indirizzo
    cap
    provincia
    citta
    nazione
    partita_iva
    sito
    pec
    giotto
    ofc
    tipo_licenza
    zoho_plan_code
    venditore
    data_scadenza
    data_scadenza_giotto
    stripe_created_at
    createdAt
    updatedAt
    percent_services_used
    limite_servizi
    total_services_current_billing_period
    spazio
    spazio_occupato
    spazio_disponibile
    spazio_allegati
  }
}`.replace(/\r?\n|\r/g, "").replace(/\t/g, " ");

export default queryOrgDetails; 