import React from "react";
import { Card, Space, Button, message } from "antd";
import { formatBytes } from "../../../utils";
import { AimOutlined, MessageOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import OrgLinks from "../../OrgDetails/OrgLinks";
import { useAuth0 } from "../../../react-auth0-spa";
import { useAppContext } from "../../../context";
import config from "../../../api_config.json";

const OrgInfo = ({ org }) => {
  const { getTokenSilently } = useAuth0();
  const { isProd } = useAppContext();
  const orgId = org.objectId;

  const aladinGifs = [
    "https://media.giphy.com/media/TrYXjFjgam6A0/giphy.gif",
    "https://media.giphy.com/media/3o7abldj0b3rxrZUxW/giphy.gif",
    "https://media.giphy.com/media/b5LTssxCLpvVe/giphy.gif",
    "https://media.giphy.com/media/xWGnQfQN5bauY/giphy.gif",
    "https://media.giphy.com/media/P8MxmGnjmytws/giphy.gif",
    "https://media.giphy.com/media/26tPplGWjN0xLybiU/giphy.gif",
    "https://media.giphy.com/media/3NtY188QaxDdC/giphy.gif",
    "https://media.giphy.com/media/10UeedrT5MIfPG/giphy.gif",
    "https://media.giphy.com/media/ma6P4l3O53uX6/giphy.gif",
    "https://media.giphy.com/media/wi7Bpses8HgaI/giphy.gif",
    "https://media.giphy.com/media/H5C8CevNMbpBqNqFjl/giphy.gif",
    "https://media.giphy.com/media/H5C8CevNMbpBqNqFjl/giphy.gif",
  ];

  const assumeOrgRole = async (orgId) => {
    try {
      message.loading("Ogni suo desiderio è un ordine", 2);
      const token = await getTokenSilently();

      const response = await fetch(
        `${config.baseUrl[isProd ? "prod" : "dev"]}/assume-org-role`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            orgId,
          }),
        }
      );

      await response.text();
      const gif = aladinGifs[Math.floor(Math.random() * aladinGifs.length)];
      message.success(<img alt="gif" src={gif} />, 2);
    } catch (error) {
      message.error("Si é verificato un problema: " + error.message);
      console.error(error);
    }
  };

  // Calculate space usage percentage
  const spaceUsedPercentage = org.spazio > 0 
    ? Math.round((org.spazio_occupato / org.spazio) * 100) 
    : 0;

  return (
    <div className="org-info-container">
      {/* Admin Action Buttons */}
      <Card title="Azioni Amministrative" className="org-info-card">
        <Space wrap>
          <Button
            onClick={() => {
              assumeOrgRole(orgId).then(() => {
                window.open(
                  "https://eterno.onoranzefunebricloud.com/logout.php",
                  "_blank"
                );
              });
              return;
            }}
            size="large"
            icon={<AimOutlined />}
          >
            Entra con Super User (Eterno)
          </Button>
          <Button
            onClick={() => {
              assumeOrgRole(orgId).then(() => {
                window.open(
                  "https://editor.giotto.onoranzefunebricloud.com/logout",
                  "_blank"
                );
              });
              return;
            }}
            size="large"
            icon={<AimOutlined />}
          >
            Entra con Super User (Giotto)
          </Button>
          <Link
            to={{
              pathname: "/send-notifications",
              record: {
                orgId,
              },
            }}
          >
            <Button
              size="large"
              icon={<MessageOutlined />}
            >
              Invia notifica
            </Button>
          </Link>
          <OrgLinks
            org={org}
            size="large"
          />
        </Space>
      </Card>
      
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <Card title="Informazioni Organizzazione" bordered={false}>
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "16px" }}>
            <div>
              <p>
                <strong>ID:</strong> {org.objectId}
              </p>
              <p>
                <strong>Tipo Istanza:</strong> {org.tipo_istanza}
              </p>
              <p>
                <strong>Piano:</strong> {org.plan}
              </p>
              <p>
                <strong>Email:</strong> {org.email}
              </p>
              <p>
                <strong>Telefono:</strong> {org.telefono1} {org.telefono2 ? `/ ${org.telefono2}` : ''}
              </p>
              <p>
                <strong>Cellulare:</strong> {org.cellulare1} {org.cellulare2 ? `/ ${org.cellulare2}` : ''}
              </p>
            </div>
            <div>
              <p>
                <strong>Indirizzo:</strong> {org.indirizzo}, {org.cap} {org.citta} ({org.provincia}), {org.nazione}
              </p>
              <p>
                <strong>Partita IVA:</strong> {org.partita_iva}
              </p>
              <p>
                <strong>Sito Web:</strong> {org.sito ? <a href={org.sito} target="_blank" rel="noopener noreferrer">{org.sito}</a> : 'N/A'}
              </p>
              <p>
                <strong>PEC:</strong> {org.pec || 'N/A'}
              </p>
              <p>
                <strong>Venditore:</strong> {org.venditore || 'N/A'}
              </p>
              <p>
                <strong>Scadenza:</strong> {org.data_scadenza ? new Date(org.data_scadenza).toLocaleDateString() : 'N/A'}
              </p>
            </div>
          </div>
        </Card>

        <Card title="Moduli" bordered={false}>
          {/* Modules content from original component */}
        </Card>

        <Card title="Dettagli Tecnici" bordered={false}>
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "16px" }}>
            <div>
              <p>
                <strong>Creato il:</strong> {new Date(org.createdAt).toLocaleDateString()}
              </p>
              <p>
                <strong>Aggiornato il:</strong> {new Date(org.updatedAt).toLocaleDateString()}
              </p>
              <p>
                <strong>Percentuale Servizi Utilizzati:</strong>{" "}
                {org.percent_services_used}%
              </p>
              <p>
                <strong>Limite Servizi:</strong> {org.limite_servizi}
              </p>
              <p>
                <strong>Servizi Utilizzati:</strong> {org.total_services_current_billing_period || 0}
              </p>
            </div>
            <div>
              <p>
                <strong>Spazio Totale:</strong> {formatBytes(org.spazio)}
              </p>
              <p>
                <strong>Spazio Occupato:</strong> {formatBytes(org.spazio_occupato)} ({spaceUsedPercentage}%)
              </p>
              <p>
                <strong>Spazio Allegati:</strong> {formatBytes(org.spazio_allegati || 0)}
              </p>
              <p>
                <strong>Giotto:</strong> {org.giotto ? 'Sì' : 'No'}
              </p>
              <p>
                <strong>OFC:</strong> {org.ofc ? 'Sì' : 'No'}
              </p>
            </div>
          </div>
        </Card>
      </Space>
    </div>
  );
};

export default OrgInfo; 