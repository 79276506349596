import React from "react";
import { Card } from "antd";

const OrgFatturazioneElettronica = ({ orgId }) => {
  return (
    <Card title="Fatturazione Elettronica" bordered={false}>
      <p>Contenuto per la sezione Fatturazione Elettronica da implementare</p>
    </Card>
  );
};

export default OrgFatturazioneElettronica; 