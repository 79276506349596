import React from "react";
import { Card } from "antd";

const OrgServizi = ({ org }) => {
  return (
    <Card title="Servizi" bordered={false}>
      <p>Contenuto per la sezione Servizi da implementare</p>
    </Card>
  );
};

export default OrgServizi; 