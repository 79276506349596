import React, { useContext } from "react";
import { useLocalStorage } from "@rehooks/local-storage";

export const AppContext = React.createContext();
export const useAppContext = () => useContext(AppContext);

export const AppProvider = (props) => {
  const isMobile = window.innerWidth <= 768;
  const defaultValue = isMobile ? true : false;
  
  const [isProd, setIsProd] = useLocalStorage("isProd", defaultValue);

  const toggleEnv = () => {
    setIsProd(!isProd);
  };

  return (
    <AppContext.Provider
      value={{
        isProd,
        toggleEnv,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
