import React, { useCallback, useEffect, useState } from "react";
import { Col, Collapse, Row, message, Skeleton } from "antd";
import { useAuth0 } from "../../../react-auth0-spa";
import config from "../../../api_config.json";
import { useAppContext } from "../../../context";
import {
  dbQueryResultsGroupedItems,
  dbQueryResultsGroupedProducts,
} from "../../OrgDetails/TabOrgFeatures/Utils/dbQueryResultsGrouped";
import FeatureGroup from "../../OrgDetails/TabOrgFeatures/FeatureGroup";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const OrgFeatures = ({ orgId, org }) => {
  const { getTokenSilently } = useAuth0();
  const { isProd } = useAppContext();
  const [features, setFeatures] = useState(undefined);
  const [disabledFeatures, setDisabledFeatures] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchFeatures = useCallback(async () => {
    try {
      const token = await getTokenSilently();

      // Using GraphQL to fetch features
      const query = `
      query FindFeatures {
        findFeatures(first: 10000, order: name_ASC) {
          edges {
            node {
              objectId
              name
              label
              description
              products {
                ... on Element {
                  value
                }
              }
              plans {
                ... on Element {
                  value
                }
              }
              modules {
                edges {
                  node {
                    objectId
                    name
                  }
                }
              }
            }
          }
        }
      }`;

      const response = await fetch(
        `${config.baseUrl[isProd ? "prod" : "dev"]}/graphql?raw=true`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ query }),
        }
      );

      const result = await response.json();

      if (result.errors) {
        throw new Error(result.errors[0].message);
      }

      // Transform the GraphQL response to match the format expected by the grouping functions
      const featuresData = result.findFeatures.edges.map((edge) => {
        const node = edge.node;
        return {
          name: node.name,
          label: node.label,
          description: node.description,
          product:
            node.products && node.products.length > 0
              ? node.products[0].value
              : "unknown",
          group:
            node.modules && node.modules.edges.length > 0
              ? node.modules.edges[0].node.name
              : "general",
          plans: node.plans ? node.plans.map((plan) => plan.value) : [],
        };
      });

      const featuresGrouped = featuresData
        .filter((item) => item.name !== undefined)
        .reduce(dbQueryResultsGroupedProducts, {});

      for (const product of Object.keys(featuresGrouped)) {
        featuresGrouped[product] = featuresGrouped[product].reduce(
          dbQueryResultsGroupedItems,
          {}
        );
      }

      setFeatures(featuresGrouped);
    } catch (error) {
      console.error("Error fetching features:", error);
      message.error("Errore nel caricamento delle funzionalità");
    }
  }, [getTokenSilently, isProd]);

  const fetchFeaturesPreferences = useCallback(async () => {
    try {
      const token = await getTokenSilently();

      const query = `
        query FindFeaturePreferences($orgId: ID!) {
          features_preferences(
            first: 10000,
            where: {org_pointer: {have: {objectId: {equalTo: $orgId}}}}
          ) {
            edges {
              node {
                objectId
                key
                product
                disabled
              }
            }
          }
        }
      `;

      const response = await fetch(
        `${config.baseUrl[isProd ? "prod" : "dev"]}/graphql?raw=true`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            query,
            variables: { orgId },
          }),
        }
      );

      const result = await response.json();

      if (result.errors) {
        throw new Error(result.errors[0].message);
      }

      // Transform the edges/node structure to a flat array
      const preferencesData =
        result.features_preferences?.edges?.map((edge) => edge.node) || [];

      // Initialize an empty object to avoid undefined errors
      const disabledFeaturesGrouped = {};

      preferencesData
        .filter((item) => item.key !== undefined)
        .forEach((item) => {
          if (!disabledFeaturesGrouped[item.product]) {
            disabledFeaturesGrouped[item.product] = {};
          }
          if (!disabledFeaturesGrouped[item.product][item.group]) {
            disabledFeaturesGrouped[item.product][item.group] = {};
          }
          disabledFeaturesGrouped[item.product][item.group][item.key] = item;
        });

      setDisabledFeatures(disabledFeaturesGrouped);
    } catch (error) {
      console.error("Error fetching feature preferences:", error);
      message.error(
        "Errore nel caricamento delle preferenze delle funzionalità"
      );
      // Initialize with empty object to prevent errors
      setDisabledFeatures({});
    }
  }, [getTokenSilently, isProd, orgId]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    await fetchFeatures();
    await fetchFeaturesPreferences();
    setLoading(false);
  }, [fetchFeatures, fetchFeaturesPreferences]);

  const setOrgFeaturePreference = async (data) => {
    try {
      const token = await getTokenSilently();

      const response = await fetch(
        `${
          config.baseUrl[isProd ? "prod" : "dev"]
        }/setFeaturePreference/${orgId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        }
      );

      const responseData = await response.json();
      return responseData;
    } catch (error) {
      console.error("Error setting feature preference:", error);
      throw error;
    }
  };

  const handleClick = async (data) => {
    if (data.key && data.disabled !== undefined) {
      message.loading({
        content: "Funzionalità in aggiornamento..",
        key: "loading",
        duration: 0,
      });

      const payload = {
        ...data,
      };
      try {
        await setOrgFeaturePreference(payload);
        await fetchFeaturesPreferences();
        message.destroy("loading");
        message.success("Funzionalità aggiornata con successo");
      } catch (error) {
        message.destroy("loading");
        message.error("Errore: Funzionalità non aggiornata");
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      {loading && <Skeleton active />}
      {!loading && features && (
        <Row>
          <Col span={24}>
            <Collapse defaultActiveKey={["eterno", "giotto"]}>
              {Object.keys(features)
                .sort((a, b) => a.localeCompare(b))
                .map((product) => {
                  return (
                    <CollapsePanel
                      header={capitalizeFirstLetter(product)}
                      key={product}
                    >
                      <Row>
                        {Object.keys(features[product]).map((key) => {
                          // Create an array from the disabledItems object if needed
                          const disabledItemsForGroup =
                            disabledFeatures[product] &&
                            disabledFeatures[product][key]
                              ? Object.values(disabledFeatures[product][key])
                              : [];

                          return (
                            <FeatureGroup
                              key={key}
                              group={key}
                              product={product}
                              items={features[product][key]}
                              disabledItems={disabledItemsForGroup}
                              handleClick={handleClick}
                              orgLicence={org.tipo_licenza}
                            />
                          );
                        })}
                      </Row>
                    </CollapsePanel>
                  );
                })}
            </Collapse>
          </Col>
        </Row>
      )}
    </>
  );
};

export default OrgFeatures;
