import React, { useState, useCallback, useEffect } from "react";
import { Form, Modal, Spin, message } from "antd";
import FeatureEditForm from "./FeatureEditForm";
import axios from "axios";
import { useAuth0 } from "../../react-auth0-spa";
import config from "../../api_config.json";
import { useAppContext } from "../../context";

const updateFeatureMutation = `
  mutation UpdateFeature($id: ID!, $fields: UpdateFeaturesFieldsInput!) {
    updateFeatures(input: { id: $id, fields: $fields }) {
      features {
        objectId
      }
    }
  }
`
  .replace(/\r?\n|\r/g, "")
  .replace(/\t/g, " ");

const createFeatureMutation = `
  mutation CreateFeature($fields: CreateFeaturesFieldsInput!) {
    createFeatures(input: { fields: $fields }) {
      features {
        objectId
      }
    }
  }
`
  .replace(/\r?\n|\r/g, "")
  .replace(/\t/g, " ");

const findModulesQuery = `
  query findModules {
    modules(first: 10000) {
      edges {
        node {
          objectId
          name
        }
      }
    }
  }
`
  .replace(/\r?\n|\r/g, "")
  .replace(/\t/g, " ");

const findPlansQuery = `
  query findPlans {
    findFeatures(first: 10000) {
      edges {
        node {
          plans {
            ... on Element {
              value
            }
          }
        }
      }
    }
  }
`
  .replace(/\r?\n|\r/g, "")
  .replace(/\t/g, " ");

const FeatureEditModal = ({ visible, feature, onCancel, onSuccess }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [modules, setModules] = useState([]);
  const [plans, setPlans] = useState([]);
  const { getTokenSilently } = useAuth0();
  const { isProd } = useAppContext();
  const env = isProd ? "prod" : "dev";
  const isCreating = !feature?.objectId;

  const fetchModules = useCallback(async () => {
    try {
      const token = await getTokenSilently();
      const response = await axios({
        method: "post",
        url: `${config.baseUrl[env]}/graphql`,
        data: {
          query: findModulesQuery,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data) {
        setModules(response.data);
      }
    } catch (error) {
      console.error("Error fetching modules:", error);
      message.error("Failed to load modules");
    }
  }, [env, getTokenSilently]);

  const fetchPlans = useCallback(async () => {
    try {
      const token = await getTokenSilently();
      const response = await axios({
        method: "post",
        url: `${config.baseUrl[env]}/graphql`,
        data: {
          query: findPlansQuery,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data) {
        // Extract unique plan values
        const allPlans = response.data
          .flatMap((node) => node.plans || [])
          .map((plan) => plan.value)
          .filter(Boolean);

        // Remove duplicates
        const uniquePlans = [...new Set(allPlans)].sort();
        setPlans(uniquePlans);
      }
    } catch (error) {
      console.error("Error fetching plans:", error);
      message.error("Failed to load plans");
    }
  }, [env, getTokenSilently]);

  useEffect(() => {
    if (visible) {
      fetchModules();
      fetchPlans();
    }
  }, [visible, fetchModules, fetchPlans]);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const token = await getTokenSilently();

      const fields = {
        name: values.name,
        label: values.label,
        description: values.description || "",
        ofc_managed: values.ofc_managed,
        ACL: {
          public: {
            read: true,
            write: false,
          },
        },
      };

      // Handle plans
      if (values.plans && values.plans.length > 0) {
        fields.plans = values.plans;
      }

      // Handle products
      if (values.products && values.products.length > 0) {
        fields.products = values.products;
      }

      if (values.module_pointer) {
        fields.module_pointer = { link: values.module_pointer };
      } else {
        fields.module_pointer = null;
      }

      let variables = { fields };

      if (isCreating) {
        const response = await axios({
          method: "post",
          url: `${config.baseUrl[env]}/graphql`,
          data: {
            query: createFeatureMutation,
            variables,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.errors) {
          throw new Error(
            response.data.errors.map((e) => e.message).join(", ")
          );
        }

        message.success("Feature creata con successo");
        onSuccess();
      } else {
        variables.id = feature.objectId;
        const response = await axios({
          method: "post",
          url: `${config.baseUrl[env]}/graphql`,
          data: {
            query: updateFeatureMutation,
            variables,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.errors) {
          throw new Error(
            response.data.errors.map((e) => e.message).join(", ")
          );
        }

        message.success("Feature aggiornata con successo");
        onSuccess();
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      message.error(error.message || "Failed to save feature");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={visible}
      title={
        isCreating ? "Nuova Feature" : `Modifica Feature - ${feature.name}`
      }
      okText={isCreating ? "Crea" : "Salva"}
      cancelText="Annulla"
      onCancel={onCancel}
      width="80%"
      style={{ top: 20 }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            handleSubmit(values);
          })
          .catch((info) => {
            console.error("Validate Failed:", info);
          });
      }}
    >
      {!loading && (
        <FeatureEditForm
          form={form}
          initialValues={feature || {}}
          isCreating={isCreating}
          modules={modules}
          plans={plans}
        />
      )}
      {loading && <Spin size="large" />}
    </Modal>
  );
};

export default FeatureEditModal;
