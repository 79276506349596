import React from "react";
import { Card } from "antd";

const OrgTimeline = ({ orgId }) => {
  return (
    <Card title="Timeline" bordered={false}>
      <p>Contenuto per la sezione Timeline da implementare</p>
    </Card>
  );
};

export default OrgTimeline; 