import React from "react";
import { Form, Input, InputNumber } from "antd";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const CreditiForm = (props) => {
  return (
    <Form
      {...layout}
      form={props.form}
      initialValues={{
        freeCreditsAmount: 0,
        paidCreditsAmount: 0,
      }}
      onFinish={props.onFinish}
    >
      <Form.Item
        label="Crediti Gratuiti"
        name="freeCreditsAmount"
        rules={[{ required: true }]}
      >
        <InputNumber />
      </Form.Item>

      <Form.Item
        label="Crediti Acquistati"
        name="paidCreditsAmount"
        rules={[{ required: true }]}
      >
        <InputNumber />
      </Form.Item>

      <Form.Item
        label="Note"
        name="note"
        rules={[{ message: "Inserire una causale" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="Riferimento Pagamento" name="paymentReference">
        <Input placeholder="Opzionale per crediti gratuiti" />
      </Form.Item>
    </Form>
  );
};

export default CreditiForm;
