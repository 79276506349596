import React from "react";
import { Card } from "antd";

const OrgGiotto = ({ orgId }) => {
  return (
    <Card title="Giotto" bordered={false}>
      <p>Contenuto per la sezione Giotto da implementare</p>
    </Card>
  );
};

export default OrgGiotto; 