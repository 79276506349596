import React, { useState, useEffect } from "react";
import { Row, Col, Spin } from 'antd';
import { ProjectBuild } from "..";
import { useAuth0 } from "../../react-auth0-spa";
import config from "../../api_config.json";
import { useAppContext } from "../../context";
import "./StatusGrid.css";
import _ from 'lodash';

const StatusGrid = () => {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const { isProd } = useAppContext();
  const { getTokenSilently } = useAuth0();

  useEffect(() => {
    const getLatestCodeBuildData = async () => {
      try {
        const token = await getTokenSilently();
        const response = await fetch(
          `${config.ci[isProd ? "prod" : "dev"]}/getSystemStatus`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        return await response.json();
      } catch (error) {
        console.error(error);
      }
    };

    let abort = false;
    let timeoutId;

    const refreshMe = async withoutRefresh => {
      setLoading(true);
      let newData = await getLatestCodeBuildData();
      if (!abort) {
        const sortedData = _.sortBy(newData, 'endTime').reverse();
        setItems(sortedData);
      }
      setLoading(false);
    };

    function scheduled(withoutRefresh) {
      refreshMe(withoutRefresh);
      timeoutId = setTimeout(scheduled, 30000);
    }

    scheduled(true);

    return () => {
      abort = true;
      clearTimeout(timeoutId);
    };
  }, [getTokenSilently, isProd]);

  return (
    <Spin spinning={loading}>
      <Row gutter={[16, 16]} style={{ padding: '16px' }}>
        {items.map((props, key) => (
          <Col 
            key={`ProjectBuild-${key}-${isProd}`}
            xs={24}    // Full width on mobile
            sm={24}    // 2 columns on small screens
            md={12}     // 3 columns on medium screens
            lg={8}     // 4 columns on large screens
            xl={8}     // 6 columns on extra large screens
          >
            <ProjectBuild {...props} />
          </Col>
        ))}
      </Row>
    </Spin>
  );
};

export default React.memo(StatusGrid);
