import React, { useState, useCallback, useEffect } from "react";
import { useAuth0 } from "../../../react-auth0-spa";
import { useAppContext } from "../../../context";
import ReactCompareImage from "react-compare-image";
import formatDistance from "date-fns/formatDistance";
import { it } from "date-fns/locale";
import InfiniteScroll from "react-infinite-scroll-component";
import { LinearProgress } from "@material-ui/core";
import { fetchAllegati } from "../../../graphql/controllers/fetchAllegati";
import { Card, Tag, Result } from "antd";
import config from "../../../api_config.json";

const OrgBlurBg = ({ orgId }) => {
  const [skipIndex, setSkipIndex] = useState(1);
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const { getTokenSilently } = useAuth0();
  const { isProd } = useAppContext();

  const fetchData = useCallback(
    async (skip = 0) => {
      const limit = 24;
      const env = isProd ? "prod" : "dev";

      const graphqlUrl = `${config.baseUrl[env]}/graphql`;

      try {
        const token = await getTokenSilently();
        const graphql = {
          url: graphqlUrl,
          token,
        };
        const variablesFetchAllegati = {
          where: {
            org_pointer: {
              have: {
                objectId: {
                  equalTo: orgId,
                },
              },
            },
            isBlurBg: {
              equalTo: true,
            },
          },
          skip: skip * limit,
          first: limit,
        };

        const response = await fetchAllegati(graphql, variablesFetchAllegati);

        return response;
      } catch (error) {
        console.error(error);
      }
    },
    [isProd, getTokenSilently, orgId]
  );

  useEffect(() => {
    fetchData(0).then((response) => {
      if (response && response.data) {
        const { data } = response;
        if (data.length === 0) {
          setHasMore(false);
        }
        setItems(data);
      }
    });
  }, [fetchData]);

  return (
    <div>
      {items.length === 0 && !hasMore ? (
        <Result
          status="info"
          title="Nessun risultato trovato"
          subTitle="Non ci sono immagini con sfondo sfocato per questa organizzazione."
        />
      ) : (
        <InfiniteScroll
          dataLength={items.length}
          next={() => {
            setSkipIndex(skipIndex + 1);
            fetchData(skipIndex).then((response) => {
              if (response && response.data) {
                const { data } = response;
                setItems(items.concat(data));
                if (data.length === 0) {
                  setHasMore(false);
                }
              }
            });
          }}
          hasMore={hasMore}
          loader={<LinearProgress />}
        >
          <div className="grid-container">
            {items.map((allegato) => {
              if (!allegato.removebgRawImage) {
                return null;
              }
              return (
                <div className="grid-item" key={allegato.objectId}>
                  <Card>
                    <div style={{ 
                      width: '100%',
                      maxWidth: '600px',
                      margin: '0 auto'
                    }}>
                      <ReactCompareImage
                        leftImage={allegato.allegato.url}
                        rightImage={allegato.removebgRawImage}
                        style={{ width: '100%', height: 'auto' }}
                      />
                    </div>
                    <Tag style={{ marginTop: "10px" }}>
                      {formatDistance(new Date(allegato.updatedAt), new Date(), {
                        locale: it,
                      })}
                    </Tag>
                    <Tag
                      style={{ marginTop: "10px" }}
                      color={
                        allegato.blurbg_provider === "pixianai"
                          ? "cyan"
                          : "purple"
                      }
                    >
                      {allegato.blurbg_provider || "blurbg"}
                    </Tag>
                  </Card>
                </div>
              );
            })}
          </div>
        </InfiniteScroll>
      )}
    </div>
  );
};

export default OrgBlurBg; 