import React, { useState, useCallback, useEffect } from "react";
import { Form, Modal, Spin, message } from "antd";
import ModuleEditForm from "./ModuleEditForm";
import axios from "axios";
import { useAuth0 } from "../../react-auth0-spa";
import config from "../../api_config.json";
import { useAppContext } from "../../context";

const updateModuleMutation = `
  mutation UpdateModule($id: ID!, $fields: UpdateModuleFieldsInput!) {
    updateModule(input: { id: $id, fields: $fields }) {
      module {
        objectId
      }
    }
  }
`
  .replace(/\r?\n|\r/g, "")
  .replace(/\t/g, " ");

const createModuleMutation = `
  mutation CreateModule($fields: CreateModuleFieldsInput!) {
    createModule(input: { fields: $fields }) {
      module {
        objectId
      }
    }
  }
`
  .replace(/\r?\n|\r/g, "")
  .replace(/\t/g, " ");

const findModuleCategoriesQuery = `
  query findModuleCategories {
    module_categories(first: 10000) {
      edges {
        node {
          objectId
          name
          color
          order
        }
      }
    }
  }
`
  .replace(/\r?\n|\r/g, "")
  .replace(/\t/g, " ");

const findServicesQuery = `
  query findServices {
    modules(first: 10000) {
      edges {
        node {
          objectId
          name
        }
      }
    }
  }
`
  .replace(/\r?\n|\r/g, "")
  .replace(/\t/g, " ");

const findModuleTargetsQuery = `
  query findModuleTargets {
    module_targets(first: 10000) {
      edges {
        node {
          objectId
          name
        }
      }
    }
  }
`
  .replace(/\r?\n|\r/g, "")
  .replace(/\t/g, " ");

const ModuleEditModal = ({ visible, module, onCancel, onSuccess }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [services, setServices] = useState([]);
  const [moduleTargets, setModuleTargets] = useState([]);
  const { getTokenSilently } = useAuth0();
  const { isProd } = useAppContext();
  const env = isProd ? "prod" : "dev";
  const isCreating = !module?.objectId;

  const defaultValues = {
    description: "",
  };

  const fetchCategories = useCallback(async () => {
    try {
      const token = await getTokenSilently();
      const response = await axios({
        method: "post",
        url: `${config.baseUrl[env]}/graphql`,
        data: {
          query: findModuleCategoriesQuery,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data) {
        const categoriesData = response.data.sort(
          (a, b) => (a.order || 0) - (b.order || 0)
        );
        setCategories(categoriesData);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
      message.error("Failed to load categories");
    }
  }, [env, getTokenSilently]);

  const fetchServices = useCallback(async () => {
    try {
      const token = await getTokenSilently();
      const response = await axios({
        method: "post",
        url: `${config.baseUrl[env]}/graphql`,
        data: {
          query: findServicesQuery,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data) {
        setServices(response.data);
      }
    } catch (error) {
      console.error("Error fetching services:", error);
      message.error("Failed to load services");
    }
  }, [env, getTokenSilently]);

  const fetchModuleTargets = useCallback(async () => {
    try {
      const token = await getTokenSilently();
      const response = await axios({
        method: "post",
        url: `${config.baseUrl[env]}/graphql`,
        data: {
          query: findModuleTargetsQuery,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data) {
        setModuleTargets(response.data);
      }
    } catch (error) {
      console.error("Error fetching module targets:", error);
      message.error("Failed to load module targets");
    }
  }, [env, getTokenSilently]);

  useEffect(() => {
    if (visible) {
      fetchCategories();
      fetchServices();
      fetchModuleTargets();
    }
  }, [visible, fetchCategories, fetchServices, fetchModuleTargets]);

  useEffect(() => {
    form.setFieldsValue({
      description: module?.description || defaultValues.description,
    });
  }, [form, module, defaultValues.description]);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const token = await getTokenSilently();

      const fields = {
        name: values.name,
        description: values.description || "",
        price: parseFloat(values.price),
        minutes_of_training: parseInt(values.minutes_of_training),
        is_main: values.is_main,
        is_product_line: values.is_product_line,
        una_tantum: values.una_tantum,
        power_by: values.power_by,
        power_by_link: values.power_by_link,
        order: parseInt(values.order),
        sku: values.sku,
        quantity: Boolean(values.quantity),
        included_quantity: parseInt(values.included_quantity || 0),
        charge_by_quantity: values.charge_by_quantity || false,
        price_for_quantity: values.price_for_quantity
          ? parseFloat(values.price_for_quantity)
          : 0,
        is_active: values.is_active,
        module_category_pointer: values.module_category_pointer
          ? {
              link: values.module_category_pointer,
            }
          : null,
        included_quantity_max_step: values.included_quantity_max_step || false,
        ACL: {
          public: {
            read: true,
            write: false,
          },
        },
      };

      // Handle related services changes
      if (!isCreating) {
        // Handle included services
        const currentIncludedServices =
          module.included_service_relation?.edges?.map(
            (edge) => edge.node.objectId
          ) || [];
        const newIncludedServices = values.includedServices || [];
        const toAddIncluded = newIncludedServices.filter(
          (id) => !currentIncludedServices.includes(id)
        );
        const toRemoveIncluded = currentIncludedServices.filter(
          (id) => !newIncludedServices.includes(id)
        );
        if (toAddIncluded.length > 0 || toRemoveIncluded.length > 0) {
          fields.included_service_relation = {};
          if (toAddIncluded.length > 0) {
            fields.included_service_relation.add = toAddIncluded;
          }
          if (toRemoveIncluded.length > 0) {
            fields.included_service_relation.remove = toRemoveIncluded;
          }
        }

        // Handle targets
        const currentTargets =
          module.target_relation?.edges?.map((edge) => edge.node.objectId) ||
          [];
        const newTargets = values.targets || [];
        const toAddTargets = newTargets.filter(
          (id) => !currentTargets.includes(id)
        );
        const toRemoveTargets = currentTargets.filter(
          (id) => !newTargets.includes(id)
        );
        if (toAddTargets.length > 0 || toRemoveTargets.length > 0) {
          fields.target_relation = {};
          if (toAddTargets.length > 0) {
            fields.target_relation.add = toAddTargets;
          }
          if (toRemoveTargets.length > 0) {
            fields.target_relation.remove = toRemoveTargets;
          }
        }

        // Handle related services
        const currentRelatedServices =
          module.related_service_relation?.edges?.map(
            (edge) => edge.node.objectId
          ) || [];
        const newRelatedServices = values.relatedServices || [];
        const toAddRelated = newRelatedServices.filter(
          (id) => !currentRelatedServices.includes(id)
        );
        const toRemoveRelated = currentRelatedServices.filter(
          (id) => !newRelatedServices.includes(id)
        );
        if (toAddRelated.length > 0 || toRemoveRelated.length > 0) {
          fields.related_service_relation = {};
          if (toAddRelated.length > 0) {
            fields.related_service_relation.add = toAddRelated;
          }
          if (toRemoveRelated.length > 0) {
            fields.related_service_relation.remove = toRemoveRelated;
          }
        }
      } else {
        // For new modules, only add if there are relations
        if (values.includedServices?.length > 0) {
          fields.included_service_relation = {
            add: values.includedServices,
          };
        }
        if (values.targets?.length > 0) {
          fields.target_relation = {
            add: values.targets,
          };
        }
        if (values.relatedServices?.length > 0) {
          fields.related_service_relation = {
            add: values.relatedServices,
          };
        }
      }

      fields.minutes_of_training = 0;

      let variables = { fields };

      if (isCreating) {
        const response = await axios({
          method: "post",
          url: `${config.baseUrl[env]}/graphql`,
          data: {
            query: createModuleMutation,
            variables,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.errors) {
          throw new Error(
            response.data.errors.map((e) => e.message).join(", ")
          );
        }

        message.success("Modulo creato con successo");
        onSuccess();
      } else {
        variables.id = module.objectId;
        const response = await axios({
          method: "post",
          url: `${config.baseUrl[env]}/graphql`,
          data: {
            query: updateModuleMutation,
            variables,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.errors) {
          throw new Error(
            response.data.errors.map((e) => e.message).join(", ")
          );
        }

        message.success("Modulo aggiornato con successo");
        onSuccess();
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      message.error(error.message || "Failed to save module");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={visible}
      title={isCreating ? "Nuovo Modulo" : `Modifica Modulo - ${module.name}`}
      okText={isCreating ? "Crea" : "Salva"}
      cancelText="Annulla"
      onCancel={onCancel}
      width="80%"
      style={{ top: 20 }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            handleSubmit(values);
          })
          .catch((info) => {
            console.error("Validate Failed:", info);
          });
      }}
    >
      {!loading && (
        <ModuleEditForm
          form={form}
          initialValues={module || {}}
          isCreating={isCreating}
          categories={categories}
          services={services}
          moduleTargets={moduleTargets}
        />
      )}
      {loading && <Spin size="large" />}
    </Modal>
  );
};

export default ModuleEditModal;
