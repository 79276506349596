const activeClientsQuery = `
query ActiveClients($first: Int!, $after: String) {
  organizzaziones(
    first: $first, 
    after: $after,
    where: { 
      tipo_istanza: { equalTo: "production" },
      AND: { stato: { equalTo: "attiva" } }
    },
    order: createdAt_DESC
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }    
    count
    edges {
      node {
        objectId
        nome
        plan
        stato
        tipo_istanza
        stripe_created_at
        giotto
        ofc
        data_scadenza
        data_scadenza_giotto
        percent_services_used
        email
        createdAt
      }
    }
  }
}`.replace(/\r?\n|\r/g, "").replace(/\t/g, " ");

export default activeClientsQuery; 