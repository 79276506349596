/**
 * Dynamic query that supports both search and filtering
 * The where clause is built dynamically on the client side
 */
const dynamicQuery = `
query DynamicQuery($first: Int!, $skip: Int, $where: OrganizzazioneWhereInput, $order: [OrganizzazioneOrder!]) {
  organizzaziones(
    first: $first,
    skip: $skip,
    where: $where,
    order: $order
  ) {
    count
    edges {
      node {
        objectId
        nome
        plan
        stato
        tipo_istanza
        stripe_created_at
        stripe_customer_id
        giotto
        ofc
        data_scadenza
        data_scadenza_giotto
        percent_services_used
        email
        createdAt
      }
    }
  }
}`.replace(/\r?\n|\r/g, "").replace(/\t/g, " ");

export default dynamicQuery; 