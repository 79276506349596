import React, { useState, useEffect, useCallback } from "react";
import {
  Breadcrumb,
  Button,
  Table,
  Input,
  Space,
  Typography,
  Tag,
  Divider,
  Badge,
  Popover,
  Descriptions,
  Modal,
} from "antd";
import axios from "axios";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useAuth0 } from "../../react-auth0-spa";
import config from "../../api_config.json";
import { useAppContext } from "../../context";
import { SearchOutlined, EditOutlined, CopyOutlined, BorderlessTableOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { formatDistance } from "date-fns";
import { it } from "date-fns/locale";
import ModuleEditModal from "./ModuleEditModal";
import ParseLogo from "../../images/logos/parse.png";
import ReactJson from "react-json-view";

const { Title } = Typography;

const styles = `
  .cursor-pointer {
    cursor: pointer;
  }
`;

const query = `
  query fetchModules($where: ModuleWhereInput) {
    modules(where: $where, first: 10000) {
      edges {
        node {
          objectId
          name
          description
          updatedAt
          createdAt
          icon {
            url
          }
          module_category_pointer {
            objectId
            name
            order
            color
            darker_color
          }
          included_service_relation {
            edges {
              node {
                objectId
                name
              }
            }
          }
          related_service_relation {
            edges {
              node {
                objectId
                name
              }
            }
          }
          target_relation {
            edges {
              node {
                objectId
                name
              }
            }
          }
          power_by
          power_by_link
          una_tantum
          quantity
          included_quantity
          charge_by_quantity
          minutes_of_training
          price
          price_for_quantity
          is_product_line
          sku
          order
          is_active
          included_quantity_max_step
          is_main
        }
      }
    }
  }
`
  .replace(/\r?\n|\r/g, "")
  .replace(/\t/g, " ");

const Modules = () => {
  const [loading, setLoading] = useState(true);
  const [modules, setModules] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [editingModule, setEditingModule] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [jsonViewerModule, setJsonViewerModule] = useState(null);
  const [isJsonModalVisible, setIsJsonModalVisible] = useState(false);
  const { isProd } = useAppContext();
  const env = isProd ? "prod" : "dev";
  const { getTokenSilently } = useAuth0();
  const graphqlUrl = `${config.baseUrl[env]}/graphql`;
  const parseDashboardUrl = config.parseDashboard[env];

  const fetchData = useCallback(async () => {
    try {
      const token = await getTokenSilently();
      const response = await axios({
        method: "post",
        url: graphqlUrl,
        data: {
          query,
          variables: {
            where: {},
          },
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const modulesData = response.data
        .map((node) => ({
          ...node,
          key: node.objectId,
          icon: node.icon?.url,
          category: node.module_category_pointer?.name || "",
          categoryColor: node.module_category_pointer?.color,
          categoryDarkerColor: node.module_category_pointer?.darker_color,
          includedServices:
            node.included_service_relation?.edges.map((e) => e.node.name) || [],
          relatedServices:
            node.related_service_relation?.edges.map((e) => e.node.name) || [],
          targets: node.target_relation?.edges.map((e) => e.node.name) || [],
        }))
        .sort((a, b) => (a.order || 0) - (b.order || 0));

      setModules(modulesData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching modules:", error);
      setLoading(false);
    }
  }, [getTokenSilently, graphqlUrl]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleEdit = (record) => {
    setEditingModule(record);
    setIsModalVisible(true);
  };

  const handleNew = () => {
    setEditingModule(null);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setEditingModule(null);
    setIsModalVisible(false);
  };

  const handleSuccess = () => {
    setEditingModule(null);
    setIsModalVisible(false);
    fetchData();
  };

  const handleClone = (record) => {
    const clonedModule = {
      ...record,
      name: `${record.name} (Copy)`,
      sku: `${record.sku}_COPY`,
      objectId: null,
    };
    setEditingModule(clonedModule);
    setIsModalVisible(true);
  };

  const expandedRowRender = (record) => {
    return (
      <div style={{ padding: "12px 48px" }}>
        <Descriptions title="Dettagli Base" column={3} size="small" bordered>
          {record.description && (
            <Descriptions.Item label="Descrizione" span={3}>
              {record.description}
            </Descriptions.Item>
          )}
          <Descriptions.Item label="Formazione">
            {record.minutes_of_training ? `${record.minutes_of_training} min` : "-"}
          </Descriptions.Item>
          {!record.is_product_line && (
            <Descriptions.Item label="Principale">
              <Tag color={record.is_main ? "purple" : "orange"}>
                {record.is_main ? "Principale" : "Aggiuntivo"}
              </Tag>
            </Descriptions.Item>
          )}
          <Descriptions.Item label="Una Tantum">
            <Tag color={record.una_tantum ? "success" : "default"}>
              {record.una_tantum ? "Si" : "No"}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Categoria">
            {record.module_category_pointer ? (
              <Tag
                color={record.module_category_pointer.darker_color || "default"}
              >
                {record.module_category_pointer.name}
              </Tag>
            ) : (
              "-"
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Linea di Prodotto">
            <Tag color={record.is_product_line ? "blue" : "default"}>
              {record.is_product_line ? "Si" : "No"}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Ordine">
            {record.order || "-"}
          </Descriptions.Item>
        </Descriptions>

        <Divider style={{ margin: "12px 0" }} />

        <Descriptions
          title="Gestione Quantità"
          column={3}
          size="small"
          bordered
        >
          <Descriptions.Item label="Gestione Quantità">
            <Tag color={record.quantity ? "blue" : "default"}>
              {record.quantity ? "Attiva" : "Non Attiva"}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Quantità Inclusa">
            {record.included_quantity || "0"}
          </Descriptions.Item>
          <Descriptions.Item label="Addebito per Quantità">
            <Tag color={record.charge_by_quantity ? "blue" : "default"}>
              {record.charge_by_quantity ? "Si" : "No"}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Prezzo per Quantità">
            {record.price_for_quantity
              ? `${record.price_for_quantity.toFixed(2)} €`
              : "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Quantità Inclusa Max Step">
            <Tag color={record.included_quantity_max_step ? "blue" : "default"}>
              {record.included_quantity_max_step ? "Si" : "No"}
            </Tag>
          </Descriptions.Item>
        </Descriptions>

        <Divider style={{ margin: "12px 0" }} />

        <Descriptions title="Relazioni" column={1} size="small" bordered>
          {record.included_service_relation?.edges?.length > 0 && (
            <Descriptions.Item label="Moduli Inclusi">
              <Space size={[0, 4]} wrap>
                {record.included_service_relation.edges.map((edge) => (
                  <Tag key={edge.node.objectId} style={{ margin: "2px" }}>
                    {edge.node.name}
                  </Tag>
                ))}
              </Space>
            </Descriptions.Item>
          )}
          {record.target_relation?.edges?.length > 0 && (
            <Descriptions.Item label="Target">
              <Space size={[0, 4]} wrap>
                {record.target_relation.edges.map((edge) => (
                  <Tag
                    key={edge.node.objectId}
                    color="cyan"
                    style={{ margin: "2px" }}
                  >
                    {edge.node.name}
                  </Tag>
                ))}
              </Space>
            </Descriptions.Item>
          )}
          {record.related_service_relation?.edges?.length > 0 && (
            <Descriptions.Item label="Servizi Correlati">
              <Space size={[0, 4]} wrap>
                {record.related_service_relation.edges.map((edge) => (
                  <Tag
                    key={edge.node.objectId}
                    color="blue"
                    style={{ margin: "2px" }}
                  >
                    {edge.node.name}
                  </Tag>
                ))}
              </Space>
            </Descriptions.Item>
          )}
        </Descriptions>

        {(record.power_by || record.power_by_link) && (
          <>
            <Divider style={{ margin: "12px 0" }} />
            <Descriptions title="Powered By" column={2} size="small" bordered>
              {record.power_by && (
                <Descriptions.Item label="Nome">
                  {record.power_by}
                </Descriptions.Item>
              )}
              {record.power_by_link && (
                <Descriptions.Item label="Link">
                  <a
                    href={record.power_by_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {record.power_by_link}
                  </a>
                </Descriptions.Item>
              )}
            </Descriptions>
          </>
        )}

        <Divider style={{ margin: "12px 0" }} />

        <Descriptions
          title="Informazioni di Sistema"
          column={2}
          size="small"
          bordered
        >
          <Descriptions.Item label="ID">{record.objectId}</Descriptions.Item>
          <Descriptions.Item label="Creato">
            {record.createdAt
              ? formatDistance(new Date(record.createdAt), new Date(), {
                  locale: it,
                  addSuffix: true,
                })
              : "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Ultimo Aggiornamento">
            {record.updatedAt
              ? formatDistance(new Date(record.updatedAt), new Date(), {
                  locale: it,
                  addSuffix: true,
                })
              : "-"}
          </Descriptions.Item>
        </Descriptions>
      </div>
    );
  };

  const columns = [
    {
      title: "#",
      dataIndex: "order",
      key: "order",
      sorter: (a, b) => (a.order || 0) - (b.order || 0),
      render: (order) => order || "-",
    },
    {
      title: "Icon",
      dataIndex: "icon",
      key: "icon",
      render: (iconUrl) =>
        iconUrl ? (
          <img
            src={iconUrl}
            alt="module icon"
            style={{
              width: 36,
              height: 36,
              objectFit: "contain",
              verticalAlign: "middle",
            }}
          />
        ) : (
          "-"
        ),
    },
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
      ...getColumnSearchProps("sku"),
      sorter: (a, b) => (a.sku || "").localeCompare(b.sku || ""),
      render: (sku) =>
        sku ? (
          <Tag color="default" style={{ background: "#fafafa" }}>
            {sku}
          </Tag>
        ) : (
          "-"
        ),
    },
    {
      title: "Prezzo",
      dataIndex: "price",
      key: "price",
      sorter: (a, b) => (a.price || 0) - (b.price || 0),
      render: (price) => (price ? `${price.toFixed(2)} €` : "-"),
    },
    {
      title: "Stato",
      dataIndex: "is_active",
      key: "is_active",
      filters: [
        { text: "Attivo", value: true },
        { text: "Inattivo", value: false },
      ],
      onFilter: (value, record) => record.is_active === value,
      sorter: (a, b) =>
        a.is_active === b.is_active ? 0 : a.is_active ? -1 : 1,
      render: (isActive) => (
        <Tag color={isActive ? "success" : "error"}>
          {isActive ? "Attivo" : "Inattivo"}
        </Tag>
      ),
    },
    {
      title: "Categoria",
      dataIndex: "category",
      key: "category",
      filters: [...new Set(modules.map((m) => m.category))].map((cat) => ({
        text: cat,
        value: cat,
      })),
      onFilter: (value, record) => record.category === value,
      sorter: (a, b) => (a.category || "").localeCompare(b.category || ""),
      render: (text, record) =>
        text ? (
          <Tag color={record.categoryDarkerColor || "default"}>{text}</Tag>
        ) : (
          "-"
        ),
    },
    {
      title: "Tipologia",
      dataIndex: "is_product_line",
      key: "is_product_line",
      filters: [
        { text: "Linea di Prodotto", value: true },
        { text: "Modulo", value: false },
      ],
      onFilter: (value, record) => record.is_product_line === value,
      sorter: (a, b) =>
        a.is_product_line === b.is_product_line
          ? 0
          : a.is_product_line
          ? -1
          : 1,
      render: (isProductLine) => (
        <Tag color={isProductLine ? "blue" : "green"}>
          {isProductLine ? "Linea di Prodotto" : "Modulo"}
        </Tag>
      ),
    },
    {
      title: "Principale",
      dataIndex: "is_main",
      key: "is_main",
      filters: [
        { text: "Principale", value: true },
        { text: "Aggiuntivo", value: false },
      ],
      onFilter: (value, record) => record.is_main === value,
      sorter: (a, b) => (a.is_main === b.is_main ? 0 : a.is_main ? -1 : 1),
      render: (isMain, record) =>
        record.is_product_line ? (
          "-"
        ) : (
          <Tag color={isMain ? "purple" : "orange"}>
            {isMain ? "Principale" : "Aggiuntivo"}
          </Tag>
        ),
    },
    {
      title: "Relazioni",
      key: "relations",
      render: (_, record) => (
        <Space size="small">
          {record.includedServices.length > 0 && (
            <Popover
              content={
                <div style={{ maxWidth: "400px" }}>
                  {record.includedServices.sort().map((service, index) => (
                    <Tag key={index} style={{ margin: "2px" }}>
                      {service}
                    </Tag>
                  ))}
                </div>
              }
              title="Moduli Inclusi"
              trigger="hover"
            >
              <Tag style={{ cursor: "pointer" }}>
                {record.includedServices.length} inclusi
              </Tag>
            </Popover>
          )}
          {record.targets.length > 0 && (
            <Popover
              content={
                <div style={{ maxWidth: "400px" }}>
                  {record.targets.sort().map((target, index) => (
                    <Tag key={index} color="cyan" style={{ margin: "2px" }}>
                      {target}
                    </Tag>
                  ))}
                </div>
              }
              title="Targets"
              trigger="hover"
            >
              <Tag color="cyan" style={{ cursor: "pointer" }}>
                {record.targets.length} targets
              </Tag>
            </Popover>
          )}
          {record.relatedServices.length > 0 && (
            <Popover
              content={
                <div style={{ maxWidth: "400px" }}>
                  {record.relatedServices.sort().map((service, index) => (
                    <Tag key={index} color="blue" style={{ margin: "2px" }}>
                      {service}
                    </Tag>
                  ))}
                </div>
              }
              title="Servizi Correlati"
              trigger="hover"
            >
              <Tag color="blue" style={{ cursor: "pointer" }}>
                {record.relatedServices.length} correlati
              </Tag>
            </Popover>
          )}
        </Space>
      ),
    },
    {
      title: "Azioni",
      key: "actions",
      render: (_, record) => (
        <Space onClick={(e) => e.stopPropagation()}>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={(e) => {
              e.stopPropagation();
              handleEdit(record);
            }}
          />
          <Button
            type="link"
            icon={<CopyOutlined />}
            onClick={(e) => {
              e.stopPropagation();
              handleClone(record);
            }}
          />
          <Button
            type="link"
            icon={<BorderlessTableOutlined />}
            onClick={(e) => {
              e.stopPropagation();
              setJsonViewerModule(record);
              setIsJsonModalVisible(true);
            }}
          />
          <a
            href={`${parseDashboardUrl}/browser/module?filters=[{"field"%3A"objectId","constraint"%3A"eq","compareTo"%3A"${record.objectId}"}]`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => e.stopPropagation()}
          >
            <img
              src={ParseLogo}
              alt="Parse Dashboard"
              style={{
                width: "20px",
                height: "20px",
                objectFit: "contain",
                verticalAlign: "middle",
              }}
            />
          </a>
        </Space>
      ),
    },
  ];

  return (
    <div style={{ margin: "16px 16px" }}>
      <style>{styles}</style>
      <div>{loading && <LinearProgress />}</div>
      <Breadcrumb style={{ marginBottom: "20px" }}>
        <Breadcrumb.Item>Configurazioni</Breadcrumb.Item>
        <Breadcrumb.Item>Moduli</Breadcrumb.Item>
      </Breadcrumb>
      <Divider />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "40px",
        }}
      >
        <Title level={3}>
          <Space>
            Moduli
            <Badge overflowCount={5000} count={modules.length} />
          </Space>
        </Title>
        <Button type="primary" onClick={handleNew}>
          Nuovo Modulo
        </Button>
      </div>
      <Table
        size="small"
        dataSource={modules}
        columns={columns}
        loading={loading}
        rowKey="key"
        pagination={false}
        scroll={{ x: true }}
        rowClassName={() => "cursor-pointer"}
        onRow={(record) => ({
          onClick: () => handleEdit(record),
        })}
        expandable={{
          expandedRowRender,
          expandRowByClick: false,
        }}
      />
      <ModuleEditModal
        visible={isModalVisible}
        module={editingModule}
        onCancel={handleCancel}
        onSuccess={handleSuccess}
      />
      <Modal
        title="Module JSON Data"
        open={isJsonModalVisible}
        onCancel={() => setIsJsonModalVisible(false)}
        width={800}
        footer={null}
        style={{ top: 20 }}
      >
        {jsonViewerModule && (
          <ReactJson
            src={jsonViewerModule}
            collapseStringsAfterLength={30}
            name={false}
            collapsed={1}
            displayDataTypes={true}
            displayObjectSize={false}
            enableClipboard={false}
            style={{ maxHeight: "89vh", overflow: "auto" }}
          />
        )}
      </Modal>
    </div>
  );
};

export default Modules;
