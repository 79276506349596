import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useAuth0 } from "../../../react-auth0-spa";
import { useAppContext } from "../../../context";
import config from "../../../api_config.json";
import queryOrgDetails from "../queries/orgDetails";
import { message } from "antd";

// Custom hook per recuperare i dettagli dell'organizzazione
const useOrgDetails = (orgId) => {
  const [org, setOrg] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getTokenSilently } = useAuth0();
  const { isProd } = useAppContext();
  const env = isProd ? "prod" : "dev";

  const fetchOrgDetails = useCallback(async () => {
    try {
      setLoading(true);
      const token = await getTokenSilently();
      const graphqlUrl = `${config.baseUrl[env]}/graphql`;

      const response = await axios({
        method: "post",
        url: graphqlUrl,
        data: {
          query: queryOrgDetails,
          variables: {
            id: orgId,
          },
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.errors) {
        throw new Error(response.data.errors[0].message);
      }

      if (response.data) {
        setOrg(response.data);
      } else {
        message.error("Organizzazione non trovata");
      }
    } catch (err) {
      console.error("Errore durante il recupero dei dettagli:", err);
      setError(err.message);
      message.error(`Si è verificato un errore: ${err.message}`);
    } finally {
      setLoading(false);
    }
  }, [orgId, getTokenSilently, env]);

  useEffect(() => {
    fetchOrgDetails();
  }, [fetchOrgDetails]);

  return { org, loading, error };
};

export default useOrgDetails;
