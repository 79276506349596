import React, { useState } from "react";
import { formatDistance } from 'date-fns';
import { Card, Typography, Spin, Button } from 'antd';
import { ReloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { useAuth0 } from "../../react-auth0-spa";
import { useAppContext } from "../../context";
import config from '../../api_config.json';
import './ProjectBuild.css';

const { Title, Text } = Typography;

function Retry({ projectName, region, hash }) {
  const [ retrying, setRetrying ] = useState(false);
  const { getTokenSilently } = useAuth0();
  const { isProd } = useAppContext();

  const retryBuild = async payload => {
    setRetrying(true);

    try {
      const token = await getTokenSilently();

      await fetch(`${config.ci[isProd ? 'prod' : 'dev']}/retryBuild`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(payload)
      });
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Button
      type="primary"
      size="small"
      icon={<ReloadOutlined spin={retrying} />}
      onClick={(e) => {
        e.stopPropagation(); // Prevent card click when clicking retry
        retryBuild({ projectName, region, hash });
      }}
      style={{ marginLeft: 'auto' }}
    >
      Retry
    </Button>
  );
}

const getStatusStyle = (status) => {
  const baseStyle = {
    height: '100%',
  };

  switch (status) {
    case 'succeeded':
      return {
        ...baseStyle,
        backgroundColor: '#f6ffed',
        border: '1px solid #b7eb8f'
      };
    case 'failed':
      return {
        ...baseStyle,
        backgroundColor: '#ff4d4f20',
        border: '1px solid #ff4d4f',
        boxShadow: '0 0 10px rgba(255, 77, 79, 0.2)'
      };
    case 'in_progress':
      return {
        ...baseStyle,
        backgroundColor: '#faad1420',
        border: '1px solid #faad14',
        boxShadow: '0 0 10px rgba(250, 173, 20, 0.3)'
      };
    case 'stopped':
      return {
        ...baseStyle,
        backgroundColor: '#722ed120',
        border: '1px solid #722ed1',
        boxShadow: '0 0 10px rgba(114, 46, 209, 0.2)'
      };
    default:
      return {
        ...baseStyle,
        backgroundColor: '#e6f7ff',
        border: '1px solid #91d5ff'
      };
  }
};

const ProjectBuild = ({ projectName, hash, id, region, buildStatus, executionTime, endTime }) => {
  const makeCodeBuildLink = (region, project, id) => {
    return `https://${region}.console.aws.amazon.com/codesuite/codebuild/projects/${project}/build/${id}/log?region=${region}`;
  };

  const externalUrl = makeCodeBuildLink(region, projectName, id);

  const handleCardClick = () => {
    window.open(externalUrl, '_blank', 'noopener,noreferrer');
  };

  const renderContent = () => {
    if (buildStatus === 'in_progress') {
      return (
        <div style={{ textAlign: 'center', padding: '20px 0' }}>
          <Spin 
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} 
            tip="Building..."
          />
        </div>
      );
    }

    return (
      <>
        {typeof endTime === 'string' && (
          <div style={{ marginBottom: 12 }}>
            <Text strong style={{ fontSize: '16px' }}>
              Last: {formatDistance(new Date(endTime), new Date(), { addSuffix: true })}
            </Text>
          </div>
        )}
        <div>
          <Text strong style={{ fontSize: '16px' }}>
            Duration: {executionTime} min
          </Text>
        </div>
      </>
    );
  };

  const cardTitle = (
    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <Title level={3} style={{ margin: 0, flex: 1 }}>{projectName}</Title>
      {(buildStatus === 'failed' || buildStatus === 'stopped') && (
        <Retry projectName={projectName} region={region} hash={hash} />
      )}
    </div>
  );

  return (
    <Card
      title={cardTitle}
      style={{
        ...getStatusStyle(buildStatus),
        cursor: 'pointer'
      }}
      onClick={handleCardClick}
    >
      <div style={{ padding: '12px 0' }}>
        {renderContent()}
      </div>
    </Card>
  );
};

export default ProjectBuild;
