export default class EdgeResizer {
  static ALLOWED_BLEND_MODES = [
    'clear',
    'source',
    'over',
    'in',
    'out',
    'atop',
    'dest',
    'dest-over',
    'dest-in',
    'dest-out',
    'dest-atop',
    'xor',
    'add',
    'saturate',
    'multiply',
    'screen',
    'overlay',
    'darken',
    'lighten',
    'colour-dodge',
    'color-dodge',
    'colour-burn',
    'color-burn',
    'hard-light',
    'soft-light',
    'difference',
    'exclusion'
  ];

  constructor(edgeResizerUrl, fileUrl) {
    if (!fileUrl) {
      throw new Error("Missing file url in EdgeResizer");
    }

    console.log('init', edgeResizerUrl, fileUrl);

    const url = new URL(fileUrl);
    this.edgeResizerUrl = edgeResizerUrl;
    this.fileUrl = url.origin + url.pathname;
    this.params = new URLSearchParams(url.search);
    this.composites = [];
    this.useWatermark = false;

    // Clean existing params
    for (const [key, value] of this.params.entries()) {
      if (!value) {
        this.params.delete(key);
      }
    }
  }

  _isImageProcessable() {
    if (this.fileUrl.endsWith(".svg")) {
      return false;
    }

    return true;
  }

  _getBaseUrl() {
    return this.edgeResizerUrl;
  }

  _getFileKey() {
    const key = this.fileUrl.split("/").pop();
    return this.useWatermark ? `4ddwm${key}` : key;
  }

  scaleToWidth(newWidthValue) {
    if (!this._isImageProcessable()) {
      return this;
    }

    this.params.set("w", newWidthValue);
    return this;
  }

  scaleToHeight(newHeightValue) {
    if (!this._isImageProcessable()) {
      return this;
    }

    this.params.set("h", newHeightValue);
    return this;
  }

  resize(newWidthValue, newHeightValue, resizeType = "cover") {
    if (!this._isImageProcessable()) {
      return this;
    }

    this.params.set("w", newWidthValue);
    this.params.set("h", newHeightValue);
    this.params.set("t", resizeType);
    return this;
  }

  blur(value) {
    if (!this._isImageProcessable()) {
      return this;
    }

    this.params.set("blur", value);
    return this;
  }

  composite(url, blendMode) {
    if (!this._isImageProcessable()) {
      return this;
    }

    if (!EdgeResizer.ALLOWED_BLEND_MODES.includes(blendMode)) {
      throw new Error(`Invalid blend mode: ${blendMode}. Must be one of: ${EdgeResizer.ALLOWED_BLEND_MODES.join(', ')}`);
    }

    this.composites.push({
      url,
      blend: blendMode,
    });

    const compositeParam = Buffer.from(
      JSON.stringify(this.composites)
    ).toString("base64");

    this.params.set("composite", compositeParam);
    return this;
  }

  extend(value) {
    if (!this._isImageProcessable()) {
      return this;
    }

    if (!Number.isInteger(value) || value < 0) {
      throw new Error("Extend value must be a positive integer");
    }

    this.params.set("extend", value);
    return this;
  }

  withWatermark() {
    this.useWatermark = true;
    return this;
  }

  toString() {
    if (!this._isImageProcessable()) {
      return this.fileUrl;
    }

    const baseUrl = this._getBaseUrl();
    console.log('baseUrl', baseUrl);
    const key = this._getFileKey();
    console.log('toString', `${baseUrl}/images/${key}?${this.params.toString()}`);
    return `${baseUrl}/images/${key}?${this.params.toString()}`;
  }
}
