const countOrgsQuery = `
query CountOrgs($searchTerm: String) {
  organizzaziones(
    where: $searchTerm ? {
      OR: [
        { nome: { matchesRegex: $searchTerm, options: "i" } },
        { objectId: { matchesRegex: $searchTerm, options: "i" } }
      ]
    } : null
  ) {
    count
  }
}`.replace(/\r?\n|\r/g, "").replace(/\t/g, " ");

export default countOrgsQuery; 