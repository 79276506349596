import React from "react";
import { Card } from "antd";
import ReactJson from "react-json-view";

const OrgJson = ({ org }) => {
  return (
    <Card title={`organizzazione.${org.objectId}`} className="org-json-card">
      <ReactJson 
        src={org} 
        name={null} 
        theme="rjv-default" 
        collapsed={1} 
        displayDataTypes={true}
        enableClipboard={true}
      />
    </Card>
  );
};

export default OrgJson; 