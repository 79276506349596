import axios from "axios";
import { useAuth0 } from "../../../react-auth0-spa";
import { useAppContext } from "../../../context";
import config from "../../../api_config.json";
import { useHistory } from "react-router-dom";
import { activeClientsQuery } from '../queries';

/**
 * Hook that provides the active clients filter functionality
 */
export const useActiveClientsFilter = (setPagination, setLoading, setOrgs, setEndCursor, setHasNextPage, setFilteredOrgsCount) => {
  const { getTokenSilently } = useAuth0();
  const { isProd } = useAppContext();
  const env = isProd ? "prod" : "dev";
  const graphqlUrl = `${config.baseUrl[env]}/graphql?raw=true`;
  const history = useHistory();

  // Apply active clients filter
  const applyActiveClientsFilter = async (pageSize, currentPage = 1) => {
    try {
      setLoading(true);
      history.push(`/orgs?filter=active`);
      
      // Always reset to page 1 when applying this filter
      currentPage = 1;
      
      const token = await getTokenSilently();
      
      // First, get the count of active clients
      const countQuery = `
        query CountActiveClients {
          organizzaziones(
            first: 10000,
            where: { 
              tipo_istanza: { equalTo: "production" },
              AND: { stato: { equalTo: "attiva" } }
            }
          ) {
            count
          }
        }
      `.replace(/\r?\n|\r/g, "").replace(/\t/g, " ");
      
      const countResponse = await axios({
        method: "post",
        url: graphqlUrl,
        data: {
          query: countQuery
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      const activeClientsCount = countResponse.data?.organizzaziones?.count || 0;
      
      // Then fetch the actual results
      const response = await axios({
        method: "post",
        url: graphqlUrl,
        data: {
          query: activeClientsQuery,
          variables: {
            first: pageSize * 5,
            after: null,
          },
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data?.organizzaziones) {
        const { edges, pageInfo, count } = response.data.organizzaziones;
        const activeClients = edges.map(edge => edge.node);
        setOrgs(activeClients);
        setEndCursor(pageInfo.endCursor);
        setHasNextPage(pageInfo.hasNextPage);
        
        // Use the count from the query response if available
        const actualCount = count || activeClientsCount;
        
        // Make sure to set a minimum value to ensure pagination works
        const paginationTotal = Math.max(actualCount, 10);
        setPagination(prev => ({
          current: currentPage,
          pageSize: pageSize,
          total: paginationTotal,
        }));
        
        // Update the filtered count for badge display
        if (setFilteredOrgsCount) {
          setFilteredOrgsCount(actualCount);
        }
      }
      
      setLoading(false);
    } catch (error) {
      console.error("Error applying active clients filter:", error);
      setLoading(false);
    }
  };

  return applyActiveClientsFilter;
}; 