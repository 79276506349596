import React, { useEffect } from "react";
import {
  Form,
  Input,
  InputNumber,
  Switch,
  Select,
  Row,
  Col,
  Divider,
  Tooltip,
  Space,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import SkuWizard from "./SkuWizard";

const { TextArea } = Input;

const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};

const layoutWithLongLabels = {
  labelCol: { span: 13 },
  wrapperCol: { span: 14 },
};

const formatPrice = (value) => {
  if (!value) return "€ 0,00";
  return `€ ${value.toLocaleString("it-IT", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;
};

const parsePrice = (value) => {
  if (!value) return 0;
  return Number(value.replace(/[^0-9.-]+/g, ""));
};

const defaultValues = {
  name: "",
  price: 0,
  minutes_of_training: 0,
  is_product_line: false,
  is_main: false,
  una_tantum: false,
  power_by: "",
  power_by_link: "",
  order: 0,
  module_category_pointer: undefined,
  quantity: false,
  included_quantity: 0,
  charge_by_quantity: false,
  price_for_quantity: 0,
  sku: "",
  is_active: true,
  relatedServices: [],
  includedServices: [],
  targets: [],
  included_quantity_max_step: false,
};

const errorMessageStyle = {
  color: "#ff4d4f",
  textAlign: "center",
  marginTop: "16px",
  padding: "8px",
  border: "1px solid #ff4d4f",
  borderRadius: "4px",
  display: "none",
};

const ModuleEditForm = ({
  form,
  initialValues,
  isCreating,
  categories,
  services,
  moduleTargets,
}) => {
  const [quantityEnabled, setQuantityEnabled] = React.useState(
    initialValues.quantity || defaultValues.quantity
  );
  
  const [isProductLine, setIsProductLine] = React.useState(
    initialValues.is_product_line || defaultValues.is_product_line
  );

  useEffect(() => {
    form.setFieldsValue({
      name: initialValues.name || defaultValues.name,
      price: initialValues.price || defaultValues.price,
      minutes_of_training:
        initialValues.minutes_of_training || defaultValues.minutes_of_training,
      is_product_line: initialValues.is_product_line || defaultValues.is_product_line,
      is_main: initialValues.is_main || defaultValues.is_main,
      una_tantum: initialValues.una_tantum || defaultValues.una_tantum,
      power_by: initialValues.power_by || defaultValues.power_by,
      power_by_link: initialValues.power_by_link || defaultValues.power_by_link,
      order: initialValues.order || defaultValues.order,
      module_category_pointer:
        initialValues.module_category_pointer?.objectId ||
        defaultValues.module_category_pointer,
      quantity: initialValues.quantity || defaultValues.quantity,
      included_quantity:
        initialValues.included_quantity || defaultValues.included_quantity,
      charge_by_quantity:
        initialValues.charge_by_quantity || defaultValues.charge_by_quantity,
      price_for_quantity:
        initialValues.price_for_quantity || defaultValues.price_for_quantity,
      sku: initialValues.sku || defaultValues.sku,
      is_active:
        initialValues.is_active !== undefined
          ? initialValues.is_active
          : defaultValues.is_active,
      relatedServices:
        initialValues.related_service_relation?.edges?.map(
          (edge) => edge.node.objectId
        ) || [],
      includedServices:
        initialValues.included_service_relation?.edges?.map(
          (edge) => edge.node.objectId
        ) || [],
      targets:
        initialValues.target_relation?.edges?.map(
          (edge) => edge.node.objectId
        ) || [],
      included_quantity_max_step:
        initialValues.included_quantity_max_step ||
        defaultValues.included_quantity_max_step,
    });

    setQuantityEnabled(initialValues.quantity || defaultValues.quantity);
    setIsProductLine(initialValues.is_product_line || defaultValues.is_product_line);
  }, [form, initialValues]);

  const handleFieldsChange = (changedFields, allFields) => {
    const values = form.getFieldsValue();
    const errorElement = document.getElementById("quantityError");

    if (changedFields.some((field) => field.name[0] === "quantity")) {
      setQuantityEnabled(values.quantity);
    }
    
    if (changedFields.some((field) => field.name[0] === "is_product_line")) {
      setIsProductLine(values.is_product_line);
    }

    if (values.included_quantity && values.included_quantity_max_step) {
      errorElement.style.display = "block";
      form.setFields([
        {
          name: "included_quantity",
          errors: [
            "Non può essere abilitato insieme a Quantità Inclusa Max Step",
          ],
        },
        {
          name: "included_quantity_max_step",
          errors: ["Non può essere abilitato insieme a Quantità"],
        },
      ]);
    } else {
      errorElement.style.display = "none";
      form.setFields([
        {
          name: "included_quantity",
          errors: [],
        },
        {
          name: "included_quantity_max_step",
          errors: [],
        },
      ]);
    }
  };

  // Filter out the current module from services list
  const filteredServices = services?.filter(
    (service) => service.objectId !== initialValues.objectId
  );

  return (
    <Form
      form={form}
      name="module_edit"
      layout="horizontal"
      onFieldsChange={handleFieldsChange}
    >
      <Row gutter={32}>
        <Col span={9}>
          <Divider orientation="left">Info</Divider>
          <Form.Item
            {...layout}
            name="name"
            label="Nome"
            rules={[
              { required: true, message: "Inserisci il nome del modulo!" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            {...layout}
            name="sku"
            label={
              <Space>
                SKU
                <SkuWizard
                  onGenerate={(sku) => form.setFieldValue("sku", sku)}
                  initialSku={form.getFieldValue("sku")}
                />
              </Space>
            }
            rules={[
              { required: true, message: "Inserisci lo SKU del modulo!" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            {...layout}
            name="description"
            label={
              <span>
                Descrizione{" "}
                <Tooltip title="Descrizione dettagliata del modulo">
                  <InfoCircleOutlined style={{ color: "#1890ff" }} />
                </Tooltip>
              </span>
            }
          >
            <TextArea rows={4} />
          </Form.Item>

          <Form.Item
            {...layout}
            name="price"
            label="Prezzo"
            rules={[{ required: true, message: "Inserisci il prezzo!" }]}
          >
            <InputNumber
              min={0}
              step={0.01}
              style={{ width: "100%" }}
              formatter={formatPrice}
              parser={parsePrice}
              disabled={quantityEnabled || isProductLine}
            />
          </Form.Item>

          <Form.Item {...layout} name="order" label="Ordine">
            <InputNumber min={0} style={{ width: "100%" }} />
          </Form.Item>
        </Col>

        <Col span={7}>
          <Divider orientation="left">Quantità e altro</Divider>
          <Form.Item
            {...layout}
            name="included_quantity"
            label="Quantità Inclusa"
          >
            <InputNumber min={0} style={{ width: "100%" }} disabled={isProductLine} />
          </Form.Item>

          <Form.Item
            {...layout}
            name="price_for_quantity"
            label="Prezzo per Quantità"
          >
            <InputNumber
              min={0}
              step={0.01}
              style={{ width: "100%" }}
              formatter={formatPrice}
              parser={parsePrice}
              disabled={!quantityEnabled || isProductLine}
            />
          </Form.Item>

          <Form.Item
            {...layout}
            name="minutes_of_training"
            label="Minuti di Formazione"
          >
            <InputNumber min={0} style={{ width: "100%" }} disabled={isProductLine}/>
          </Form.Item>

          <Form.Item {...layout} name="power_by" label="Powered By Nome">
            <Input disabled={isProductLine} />
          </Form.Item>

          <Form.Item {...layout} name="power_by_link" label="Powered By Link">
            <Input disabled={isProductLine} />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Divider orientation="left">Configurazione</Divider>
          <Form.Item
            {...layoutWithLongLabels}
            name="is_active"
            label={
              <span>
                Attivo{" "}
                <Tooltip title="Indica se il modulo è attualmente disponibile per l'acquisto">
                  <InfoCircleOutlined style={{ color: "#1890ff" }} />
                </Tooltip>
              </span>
            }
            valuePropName="checked"
          >
            <Switch defaultChecked />
          </Form.Item>

          <Form.Item
            {...layoutWithLongLabels}
            name="is_product_line"
            label={
              <span>
                Linea di Prodotto{" "}
                <Tooltip title="Imposta questo modulo come linea di prodotto principale (ad es. Eterno, Funerama, Add-On)">
                  <InfoCircleOutlined style={{ color: "#1890ff" }} />
                </Tooltip>
              </span>
            }
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <Form.Item
            {...layoutWithLongLabels}
            name="is_main"
            label={
              <span>
                Modulo Principale{" "}
                <Tooltip title="">
                  <InfoCircleOutlined style={{ color: "#1890ff" }} />
                </Tooltip>
              </span>
            }
            valuePropName="checked"
          >
            <Switch disabled={isProductLine} />
          </Form.Item>

          <Form.Item
            {...layoutWithLongLabels}
            name="una_tantum"
            label={
              <span>
                Una Tantum{" "}
                <Tooltip title="Il servizio viene addebitato una sola volta">
                  <InfoCircleOutlined style={{ color: "#1890ff" }} />
                </Tooltip>
              </span>
            }
            valuePropName="checked"
          >
            <Switch disabled={isProductLine} />
          </Form.Item>

          <Form.Item
            {...layoutWithLongLabels}
            name="quantity"
            label={
              <span>
                Quantità{" "}
                <Tooltip title="Abilita la gestione della quantità per questo modulo">
                  <InfoCircleOutlined style={{ color: "#1890ff" }} />
                </Tooltip>
              </span>
            }
            valuePropName="checked"
          >
            <Switch disabled={isProductLine} />
          </Form.Item>

          <Form.Item
            {...layoutWithLongLabels}
            name="charge_by_quantity"
            label={
              <span>
                Addebito per Quantità{" "}
                <Tooltip title="Il prezzo del modulo viene moltiplicato per la quantità">
                  <InfoCircleOutlined style={{ color: "#1890ff" }} />
                </Tooltip>
              </span>
            }
            valuePropName="checked"
          >
            <Switch disabled={isProductLine} />
          </Form.Item>

          <Form.Item
            {...layoutWithLongLabels}
            name="included_quantity_max_step"
            label={
              <span>
                Quantità Inclusa Max Step{" "}
                <Tooltip title="Imposta la quantità inclusa al valore massimo dello scaglione di appartenenza">
                  <InfoCircleOutlined style={{ color: "#1890ff" }} />
                </Tooltip>
              </span>
            }
            valuePropName="checked"
          >
            <Switch disabled={isProductLine} />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Divider orientation="left">Relazioni</Divider>
          <Form.Item
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            name="module_category_pointer"
            label="Categoria"
          >
            <Select
              allowClear
              placeholder="Seleziona una categoria"
              optionFilterProp="children"
              showSearch
              disabled={isProductLine}
            >
              {categories.map((category) => (
                <Select.Option
                  key={category.objectId}
                  value={category.objectId}
                >
                  {category.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            name="includedServices"
            label="Moduli Inclusi"
          >
            <Select
              mode="multiple"
              allowClear
              placeholder="Seleziona i moduli inclusi"
              optionFilterProp="children"
              showSearch
              disabled={isProductLine}
            >
              {filteredServices?.map((service) => (
                <Select.Option key={service.objectId} value={service.objectId}>
                  {service.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            name="targets"
            label="Target"
          >
            <Select
              mode="multiple"
              allowClear
              placeholder="Seleziona i target"
              optionFilterProp="children"
              showSearch
            >
              {moduleTargets?.map((target) => (
                <Select.Option key={target.objectId} value={target.objectId}>
                  {target.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            name="relatedServices"
            label="Servizi Correlati"
          >
            <Select
              mode="multiple"
              allowClear
              placeholder="Seleziona i servizi correlati"
              optionFilterProp="children"
              showSearch
            >
              {filteredServices?.map((service) => (
                <Select.Option key={service.objectId} value={service.objectId}>
                  {service.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <div id="quantityError" style={errorMessageStyle}>
            Attenzione: Quantità e Quantità Inclusa Max Step non possono essere
            abilitati contemporaneamente
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default ModuleEditForm;
