import React, { useState, useEffect } from "react";
import {
  Affix,
  Layout,
  Typography,
  Menu,
  Switch,
  Space,
  Divider,
  Button,
  Drawer,
} from "antd";
import { LogoutOutlined, MenuOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useAppContext } from "../../context";
import { useAuth0 } from "../../react-auth0-spa";
import { sidebarItems, routeAccessConfig } from "../../config";

const { Title, Text } = Typography;
const { Sider } = Layout;
const { SubMenu } = Menu;

function LogoutButton() {
  const { logout } = useAuth0();

  const logoutCss = {
    position: "absolute",
    top: "90vh",
    left: "50%",
    marginLeft: "-12px",
  };

  return (
    <Button
      size="small"
      icon={<LogoutOutlined />}
      onClick={() => logout({ returnTo: window.location.origin })}
      style={logoutCss}
    ></Button>
  );
}

function LinkWrapper({ url, children }) {
  const isExternal = url.startsWith('http://') || url.startsWith('https://');
  
  if (isExternal) {
    return <a href={url} target="_blank" rel="noopener noreferrer">{children}</a>;
  }
  
  return <Link to={url}>{children}</Link>;
}

function Sidebar() {
  const [collapsed, setCollapsed] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const { isProd, toggleEnv } = useAppContext();
  const { user, logout } = useAuth0();

  // Check if the screen is mobile size
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    
    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  // Automatically collapse sidebar on mobile
  useEffect(() => {
    if (isMobile) {
      setCollapsed(true);
    }
  }, [isMobile]);

  // Function to check if user has access to a menu item based on its URL
  const hasAccess = (url) => {
    if (!url) return true;
    
    // Default to requiring staff access
    let allowNonStaff = false;
    let requiresOfcAdmin = false;
    
    // Check if the URL matches any of our route patterns
    for (const routeConfig of routeAccessConfig) {
      const regex = new RegExp(routeConfig.pattern);
      if (regex.test(url)) {
        allowNonStaff = routeConfig.allowNonStaff || false;
        requiresOfcAdmin = routeConfig.restrictAccessToOfcAdmin || false;
        break;
      }
    }
    
    return (user?.isOfcStaff || allowNonStaff) && 
           (!requiresOfcAdmin || user?.isOfcAdmin);
  };

  // Filter submenu items that user has access to
  const filterAccessibleItems = (items) => {
    if (!items) return [];
    return items.filter(item => hasAccess(item.url));
  };

  // Render sidebar items recursively
  const renderSidebarItems = () => {
    return sidebarItems.map((sidebarItem) => {
      // Skip items user doesn't have access to
      if (!hasAccess(sidebarItem.url)) {
        return null;
      }

      if (sidebarItem.items) {
        const accessibleItems = filterAccessibleItems(sidebarItem.items);
        
        // If no accessible subitems, don't show the submenu
        if (accessibleItems.length === 0) {
          return null;
        }

        return (
          <SubMenu
            title={sidebarItem.title}
            icon={sidebarItem.icon}
            key={`submenu-${sidebarItem.title}`}
          >
            {accessibleItems.map((subItem) => {
              if (subItem.items) {
                const accessibleSubItems = filterAccessibleItems(subItem.items);
                
                // If no accessible sub-subitems, don't show the sub-submenu
                if (accessibleSubItems.length === 0) {
                  return null;
                }

                return (
                  <SubMenu
                    title={subItem.title}
                    icon={subItem.icon}
                    key={`sub-submenu-${subItem.title}`}
                  >
                    {accessibleSubItems.map((subSubItem) => (
                      <Menu.Item key={subSubItem.title}>
                        <LinkWrapper url={subSubItem.url}>
                          {subSubItem.title}
                        </LinkWrapper>
                      </Menu.Item>
                    ))}
                  </SubMenu>
                );
              }

              return (
                <Menu.Item
                  key={subItem.title}
                  icon={subItem.icon}
                >
                  <LinkWrapper url={subItem.url}>
                    {subItem.title}
                  </LinkWrapper>
                </Menu.Item>
              );
            })}
          </SubMenu>
        );
      }

      return (
        <Menu.Item key={sidebarItem.title} icon={sidebarItem.icon}>
          <LinkWrapper url={sidebarItem.url}>
            {sidebarItem.title}
          </LinkWrapper>
        </Menu.Item>
      );
    }).filter(Boolean); // Remove null items
  };

  // Mobile menu toggle button
  const MobileMenuButton = () => (
    <Button 
      type="primary" 
      icon={<MenuOutlined />} 
      onClick={() => setDrawerVisible(true)}
      style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        zIndex: 1000,
        borderRadius: '50%',
        width: '50px',
        height: '50px',
        display: isMobile ? 'flex' : 'none',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)'
      }}
    />
  );

  // Render the sidebar content (used in both desktop and mobile views)
  const renderSidebarContent = () => (
    <>
      <Space align="center">
        <img
          src="https://cdn.onoranzefunebricloud.com/logos/singolo_white.png"
          width="61"
          height="40"
          alt="logo"
          style={{
            marginTop: "8px",
            marginBottom: "8px",
            marginLeft: "8px",
            marginRight: collapsed && !isMobile ? "8px" : "0",
          }}
        />
        {(!collapsed || isMobile) && (
          <Title
            level={2}
            style={{
              color: "rgba(255, 255, 255)",
              lineHeight: "1em",
              margin: "8px 0",
            }}
          >
            Mission Control
          </Title>
        )}
      </Space>
      <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline">
        {renderSidebarItems()}
      </Menu>

      <Divider />

      <div style={{ textAlign: "center" }}>
        <Space direction="vertical">
          <Text
            type="secondary"
            style={{ color: "rgba(255, 255, 255, 0.65)" }}
            level={4}
          >
            {collapsed && !isMobile ? "Env" : "Environment"}
          </Text>
          <Switch
            defaultChecked={isProd}
            checkedChildren={collapsed && !isMobile ? "P" : "Production"}
            unCheckedChildren={collapsed && !isMobile ? "D" : "Development"}
            onChange={toggleEnv}
          />
        </Space>
      </div>
      {!isMobile && <LogoutButton />}
    </>
  );

  return (
    <>
      {/* Desktop Sidebar */}
      {!isMobile && (
        <Affix offsetTop={0}>
          <Sider
            collapsible
            collapsed={collapsed}
            onCollapse={() => setCollapsed(!collapsed)}
            style={{ height: "100vh" }}
          >
            {renderSidebarContent()}
          </Sider>
        </Affix>
      )}

      {/* Mobile Drawer */}
      {isMobile && (
        <>
          <Drawer
            placement="left"
            closable={true}
            onClose={() => setDrawerVisible(false)}
            open={drawerVisible}
            width={250}
            bodyStyle={{ padding: 0, backgroundColor: '#001529' }}
          >
            {renderSidebarContent()}
            <Button
              size="small"
              icon={<LogoutOutlined />}
              onClick={() => logout({ returnTo: window.location.origin })}
              style={{
                position: "absolute",
                bottom: "20px",
                left: "50%",
                marginLeft: "-12px",
              }}
            ></Button>
          </Drawer>
          <MobileMenuButton />
        </>
      )}
    </>
  );
}

export default Sidebar;
