import React, { useState, useEffect } from 'react';
import { Button, Menu, Dropdown, Space, Tag, Select, Input, Popover, Form, Switch } from "antd";
import { PlusOutlined, FilterOutlined, CloseOutlined } from "@ant-design/icons";
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';

const { Search } = Input;

/**
 * Enhanced Filters component for the OrgsV2 component
 * Supports multiple column filters, search, and combined filtering
 * Now works with combined search and filter functionality
 * Added support for choosing logical operations (AND, OR) for each filter independently
 * Supports deep linking through URL parameters
 */
const FiltersComponent = ({ 
  resetFilters,
  onFilterChange,
  activeFilters = [],
  showTagsInline = true,
  searchValue = "",
  onSearchChange,
  onSearch,
  onClear
}) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const location = useLocation();
  const history = useHistory();

  // Parse filters from URL on mount
  useEffect(() => {
    const params = queryString.parse(location.search);
    if (params.filters) {
      try {
        const parsedFilters = JSON.parse(decodeURIComponent(params.filters));
        if (Array.isArray(parsedFilters) && parsedFilters.length > 0) {
          // Pass skipQuery=true when the change comes from URL
          onFilterChange(parsedFilters, true);
        }
      } catch (error) {
        console.error('Error parsing filters from URL:', error);
      }
    }
  }, []);

  // Update URL when filters change
  useEffect(() => {
    // Skip URL update if it's coming from a filter change
    if (activeFilters.length > 0) {
      const currentParams = queryString.parse(location.search);
      const newParams = {
        ...currentParams,
        filters: encodeURIComponent(JSON.stringify(activeFilters))
      };

      // Remove undefined values
      Object.keys(newParams).forEach(key => {
        if (newParams[key] === undefined) {
          delete newParams[key];
        }
      });

      const newSearch = queryString.stringify(newParams);
      const newUrl = `${location.pathname}${newSearch ? `?${newSearch}` : ''}`;
      
      // Update URL without adding to browser history and without triggering a re-render
      history.replace(newUrl, { skipQuery: true });
    }
  }, [activeFilters, location.pathname, history]);

  // Quick filters menu
  const quickFiltersMenu = (
    <Menu>
      {/* Clienti Paganti Section */}
      <Menu.ItemGroup title="Org attive">
        <Menu.Item 
          key="active-clients" 
          onClick={() => {
            // Instead of resetting filters and then adding new ones,
            // directly set the new filters in a single operation
            onFilterChange([
              {
                id: Date.now(),
                column: "tipo_istanza",
                columnName: "Istanza",
                value: "production",
                displayValue: "production",
                operator: "equalTo",
                logicalOperator: "AND"
              },
              {
                id: Date.now() + 1,
                column: "stato",
                columnName: "Stato",
                value: "attiva",
                displayValue: "attiva",
                operator: "equalTo",
                logicalOperator: "AND"
              }
            ]);
          }}
        >
          Org attive
        </Menu.Item>
        <Menu.Item 
          key="stripe-customers" 
          onClick={() => {
            // Instead of resetting filters and then adding new ones,
            // directly set the new filters in a single operation
            onFilterChange([
              {
                id: Date.now(),
                column: "stripe_customer_id",
                columnName: "Stripe Customer ID",
                value: true,
                displayValue: "Definito",
                operator: "exists",
                logicalOperator: "AND"
              },
              {
                id: Date.now() + 1,
                column: "tipo_istanza",
                columnName: "Istanza",
                value: "production",
                displayValue: "production",
                operator: "equalTo",
                logicalOperator: "AND"
              },
              {
                id: Date.now() + 2,
                column: "stato",
                columnName: "Stato",
                value: "attiva",
                displayValue: "attiva",
                operator: "equalTo",
                logicalOperator: "AND"
              }
            ]);
          }}
        >
          Org attive <strong>con</strong> abbonamento Stripe
        </Menu.Item>
        <Menu.Item 
          key="active-without-stripe" 
          onClick={() => {
            // Instead of resetting filters and then adding new ones,
            // directly set the new filters in a single operation
            onFilterChange([
              {
                id: Date.now(),
                column: "tipo_istanza",
                columnName: "Istanza",
                value: "production",
                displayValue: "production",
                operator: "equalTo",
                logicalOperator: "AND"
              },
              {
                id: Date.now() + 1,
                column: "stato",
                columnName: "Stato",
                value: "attiva",
                displayValue: "attiva",
                operator: "equalTo",
                logicalOperator: "AND"
              },
              {
                id: Date.now() + 2,
                column: "stripe_customer_id",
                columnName: "Stripe Customer ID",
                value: false,
                displayValue: "false",
                operator: "exists",
                logicalOperator: "AND"
              }
            ]);
          }}
        >
          Org attive <strong>senza</strong> abbonamento Stripe
        </Menu.Item>        
        <Menu.Item 
          key="new-clients" 
          onClick={() => {
            // Instead of resetting filters and then adding new ones,
            // directly set the new filters in a single operation
            
            // Calculate date 45 days ago
            const date45DaysAgo = new Date();
            date45DaysAgo.setDate(date45DaysAgo.getDate() - 45);
            const isoDate = date45DaysAgo.toISOString();

            onFilterChange([
              {
                id: Date.now(),
                column: "createdAt",
                columnName: "Data Creazione Org",
                value: isoDate,
                displayValue: "ultimi 45 giorni",
                operator: "greaterThanOrEqualTo",
                logicalOperator: "AND"
              },
              {
                id: Date.now() + 1,
                column: "tipo_istanza",
                columnName: "Istanza",
                value: "production",
                displayValue: "production",
                operator: "equalTo",
                logicalOperator: "AND"
              },
              {
                id: Date.now() + 2,
                column: "stato",
                columnName: "Stato",
                value: "attiva",
                displayValue: "attiva",
                operator: "equalTo",
                logicalOperator: "AND"
              }
            ]);
          }}
        >
          Org attive (<strong>ultimi 45 giorni</strong>)
        </Menu.Item>
        <Menu.Item 
          key="e-invoicing" 
          onClick={() => {
            // Instead of resetting filters and then adding new ones,
            // directly set the new filters in a single operation
            onFilterChange([
              {
                id: Date.now(),
                column: "fatturazione_attiva",
                columnName: "Fatturazione Elettronica",
                value: true,
                displayValue: "Si",
                operator: "equalTo",
                logicalOperator: "AND"
              },
              {
                id: Date.now() + 1,
                column: "stato",
                columnName: "Stato",
                value: "attiva",
                displayValue: "attiva",
                operator: "equalTo",
                logicalOperator: "AND"
              },
              {
                id: Date.now() + 2,
                column: "tipo_istanza",
                columnName: "Istanza",
                value: "production",
                displayValue: "production",
                operator: "equalTo",
                logicalOperator: "AND"
              }
            ]);
          }}
        >
          Org attive con <strong>Fatturazione Elettronica</strong>
        </Menu.Item>
        <Menu.Item 
          key="obituaries" 
          onClick={() => {
            // Instead of resetting filters and then adding new ones,
            // directly set the new filters in a single operation
            onFilterChange([
              {
                id: Date.now(),
                column: "annunci_funebri_attivi",
                columnName: "Annunci Funebri",
                value: true,
                displayValue: "Si",
                operator: "equalTo",
                logicalOperator: "AND"
              },
              {
                id: Date.now() + 1,
                column: "stato",
                columnName: "Stato",
                value: "attiva",
                displayValue: "attiva",
                operator: "equalTo",
                logicalOperator: "AND"
              },
              {
                id: Date.now() + 2,
                column: "tipo_istanza",
                columnName: "Istanza",
                value: "production",
                displayValue: "production",
                operator: "equalTo",
                logicalOperator: "AND"
              }
            ]);
          }}
        >
          Org attive con <strong>Annunci Funebri</strong>
        </Menu.Item>    
      </Menu.ItemGroup>

      <Menu.ItemGroup title="Altro">        
        <Menu.Item 
          key="demo-instances" 
          onClick={() => {
            // Instead of resetting filters and then adding new ones,
            // directly set the new filters in a single operation
            onFilterChange([
              {
                id: Date.now(),
                column: "tipo_istanza",
                columnName: "Istanza",
                value: "demo",
                displayValue: "demo",
                operator: "equalTo",
                logicalOperator: "AND"
              },
              {
                id: Date.now() + 1,
                column: "stato",
                columnName: "Stato",
                value: "attiva",
                displayValue: "attiva",
                operator: "equalTo",
                logicalOperator: "AND"
              }
            ]);
          }}
        >
          Istanze Demo attive
        </Menu.Item>
        <Menu.Item 
          key="free-instances" 
          onClick={() => {
            // Instead of resetting filters and then adding new ones,
            // directly set the new filters in a single operation
            onFilterChange([
              {
                id: Date.now(),
                column: "tipo_istanza",
                columnName: "Istanza",
                value: "free",
                displayValue: "free",
                operator: "equalTo",
                logicalOperator: "AND"
              },
              {
                id: Date.now() + 1,
                column: "stato",
                columnName: "Stato",
                value: "attiva",
                displayValue: "attiva",
                operator: "equalTo",
                logicalOperator: "AND"
              }
            ]);
          }}
        >
          Istanze Free attive
        </Menu.Item>
      </Menu.ItemGroup>

      <Menu.ItemGroup title="Query Comuni">        
        <Menu.Item 
          key="org-demo-ofc" 
          onClick={() => {
            // Create a mock event object with target.value property
            const mockEvent = {
              target: {
                value: "ONORANZE FUNEBRI OFC"
              }
            };
            
            // Set the search value to "ONORANZE FUNEBRI OFC" using the mock event
            if (onSearchChange) {
              onSearchChange(mockEvent);
            }
            
            // If there's a search function, trigger it
            if (onSearch) {
              onSearch("ONORANZE FUNEBRI OFC");
            }
          }}
        >
          Org Demo OFC
        </Menu.Item>
      </Menu.ItemGroup>

      <Menu.Divider />
      <Menu.Item key="reset" onClick={() => {
        resetFilters();
        // Clear filters from URL
        const currentParams = queryString.parse(location.search);
        delete currentParams.filters;
        const newSearch = queryString.stringify(currentParams);
        history.replace(`${location.pathname}${newSearch ? `?${newSearch}` : ''}`);
      }} danger>
        Reset Filters
      </Menu.Item>
    </Menu>
  );

  // Use a static curated configuration instead of dynamic calculation
  // This ensures names of columns and keys match correctly
  const filterableColumns = [
    {
      title: "Istanza",
      key: "tipo_istanza",
      dataIndex: "tipo_istanza",
      options: [
        { text: 'free', value: 'free' },
        { text: 'demo', value: 'demo' },
        { text: 'production', value: 'production' }
      ]
    },
    {
      title: "Stato",
      key: "stato",
      dataIndex: "stato",
      options: [
        { text: 'attiva', value: 'attiva' },
        { text: 'disattiva', value: 'disattiva' }
      ]
    },
    {
      title: "Integrazione Annunci Funebri",
      key: "integrazione_annunci_funebri",
      dataIndex: "integrazione_annunci_funebri",
      type: "boolean",
      options: [
        { text: 'Si', value: true },
        { text: 'No', value: false }
      ]
    },
    {
      title: "Fatturazione Elettronica",
      key: "fatturazione_attiva",
      dataIndex: "fatturazione_attiva",
      type: "boolean",
      options: [
        { text: 'Si', value: true },
        { text: 'No', value: false }
      ]
    },
    {
      title: "Stripe Customer ID",
      key: "stripe_customer_id",
      dataIndex: "stripe_customer_id",
      type: "string"
    },
    {
      title: "Data Creazione Org",
      key: "createdAt",
      dataIndex: "createdAt",
      type: "date"
    }
  ];

  // Add new filter handler
  const handleAddFilter = (values) => {
    console.log('Form values:', values);
    const isExistsOperator = values.operator === 'exists';
    
    // Check if we have valid inputs
    if (values.column && (values.value !== undefined && values.value !== null)) {
      const selectedColumn = filterableColumns.find(c => c.key === values.column);
      
      let displayValue;
      if (isExistsOperator) {
        displayValue = values.value ? 'esiste' : 'non esiste';
      } else if (selectedColumn?.type === 'boolean') {
        displayValue = values.value ? 'Si' : 'No';
      } else {
        displayValue = selectedColumn?.options?.find(opt => opt.value === values.value)?.text || values.value;
      }

      const newFilter = {
        id: Date.now(),
        column: values.column,
        columnName: selectedColumn?.title || values.column,
        value: values.value,
        displayValue: displayValue,
        operator: values.operator,
        logicalOperator: values.logicalOperator || "AND"
      };
      
      console.log('Adding new filter:', newFilter);

      // Don't skip the query here - this is the final action that should trigger a query
      onFilterChange([
        ...activeFilters,
        newFilter
      ]);
      form.resetFields();
      setVisible(false);
    }
  };

  // Remove filter handler
  const handleRemoveFilter = (filterId) => {
    onFilterChange(activeFilters.filter(f => f.id !== filterId));
  };

  // Get tag color based on logical operator
  const getTagColor = (logicalOperator) => {
    switch (logicalOperator) {
      case 'OR':
        return 'orange';
      case 'AND':
      default:
        return 'blue';
    }
  };

  // Filter form content
  const filterForm = (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleAddFilter}
      style={{ width: 300 }}
      initialValues={{ logicalOperator: "AND", operator: "equalTo", value: false }}
    >
      <Form.Item
        name="column"
        label="Filter by column"
        rules={[{ required: true, message: 'Please select a column' }]}
      >
        <Select placeholder="Select column">
          {filterableColumns.map(column => (
            <Select.Option key={column.key} value={column.key}>
              {column.title}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="operator"
        label="Operator"
        rules={[{ required: true, message: 'Please select an operator' }]}
      >
        <Select 
          placeholder="Select operator"
          onChange={(value) => {
            // If exists operator is selected, set value to true by default
            if (value === 'exists') {
              form.setFieldsValue({ value: true });
            }
          }}
        >
          <Select.Option value="equalTo">Equal to</Select.Option>
          <Select.Option value="notEqualTo">Not equal to</Select.Option>
          <Select.Option value="greaterThan">Greater than</Select.Option>
          <Select.Option value="lessThan">Less than</Select.Option>
          <Select.Option value="greaterThanOrEqualTo">Greater than or equal to</Select.Option>
          <Select.Option value="lessThanOrEqualTo">Less than or equal to</Select.Option>
          <Select.Option value="contains">Contains</Select.Option>
          <Select.Option value="exists">Exists</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="logicalOperator"
        label="Logical Operator"
        rules={[{ required: true, message: 'Please select a logical operator' }]}
      >
        <Select>
          <Select.Option value="AND">AND</Select.Option>
          <Select.Option value="OR">OR</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        label="Value"
        dependencies={['column', 'operator']}
      >
        {({ getFieldValue }) => {
          const columnKey = getFieldValue('column');
          const operator = getFieldValue('operator');
          const selectedColumn = filterableColumns.find(c => c.key === columnKey);
          
          // For exists operator, show a boolean switch
          if (operator === 'exists') {
            return (
              <Form.Item
                name="value"
                valuePropName="checked"
                noStyle
              >
                <Switch
                  checkedChildren="Si"
                  unCheckedChildren="No"
                  defaultChecked={true}
                />
              </Form.Item>
            );
          }
          
          if (selectedColumn?.type === 'boolean') {
            return (
              <Form.Item
                name="value"
                valuePropName="checked"
                noStyle
              >
                <Switch
                  checkedChildren="Si"
                  unCheckedChildren="No"
                />
              </Form.Item>
            );
          } else if (selectedColumn?.options) {
            return (
              <Form.Item
                name="value"
                rules={[{ required: true, message: 'Please enter a value' }]}
                noStyle
              >
                <Select placeholder="Select value">
                  {selectedColumn.options.map(option => (
                    <Select.Option key={String(option.value)} value={option.value}>
                      {option.text}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            );
          }
          
          return (
            <Form.Item
              name="value"
              rules={[{ required: true, message: 'Please enter a value' }]}
              noStyle
            >
              <Input placeholder="Enter value" />
            </Form.Item>
          );
        }}
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Add Filter
        </Button>
      </Form.Item>
    </Form>
  );

  return (
    <div>
      <Space wrap style={{ marginBottom: showTagsInline ? 8 : 0 }}>
        {/* Add filter button with popover form */}
        <Popover 
          content={filterForm} 
          title="Add Filter" 
          trigger="click"
          visible={visible}
          onVisibleChange={setVisible}
        >
          <Button icon={<PlusOutlined />}>Add Filter</Button>
        </Popover>

        {/* Quick filters dropdown */}
        <Dropdown overlay={quickFiltersMenu}>
          <Button icon={<FilterOutlined />}>
            Quick Filters
          </Button>
        </Dropdown>

        {/* Search field */}
        {onSearch && (
          <Search
            placeholder="Cerca per nome"
            allowClear
            enterButton
            value={searchValue}
            onChange={onSearchChange}
            onSearch={onSearch}
            onClear={onClear}
            style={{ width: 400, marginLeft: 16 }}
          />
        )}

        {/* Reset all filters */}
        {activeFilters.length > 0 && (
          <Button 
            icon={<CloseOutlined />} 
            onClick={() => {
              resetFilters();
              // Clear filters from URL
              const currentParams = queryString.parse(location.search);
              delete currentParams.filters;
              const newSearch = queryString.stringify(currentParams);
              history.replace(`${location.pathname}${newSearch ? `?${newSearch}` : ''}`);
            }}
            danger
          >
            Clear All
          </Button>
        )}
      </Space>

      {/* Display active filters as tags */}
      {activeFilters.length > 0 && showTagsInline && (
        <Space wrap>
          {activeFilters.map(filter => (
            <Tag 
              key={filter.id} 
              closable 
              onClose={() => handleRemoveFilter(filter.id)}
              color={getTagColor(filter.logicalOperator)}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Space>
                {`${filter.columnName} ${filter.operator || 'equalTo'} ${filter.displayValue} (${filter.logicalOperator || "AND"})`}
              </Space>
            </Tag>
          ))}
        </Space>
      )}
    </div>
  );
};

// Export a separate component for filter tags
export const FilterTags = ({ activeFilters = [], onRemoveFilter }) => {
  // Get tag color based on logical operator
  const getTagColor = (logicalOperator) => {
    switch (logicalOperator) {
      case 'OR':
        return 'orange';
      case 'AND':
      default:
        return 'blue';
    }
  };

  const getOperatorDisplay = (operator) => {
    switch (operator) {
      case 'equalTo':
        return '=';
      case 'notEqualTo':
        return '≠';
      case 'greaterThan':
        return '>';
      case 'greaterThanOrEqualTo':
        return '≥';
      case 'lessThan':
        return '<';
      case 'lessThanOrEqualTo':
        return '≤';
      case 'exists':
        return 'exists';
      default:
        return operator;
    }
  };

  if (activeFilters.length === 0) {
    return null;
  }

  return (
    <div className="filters-container">
      <div style={{ marginRight: 8, fontWeight: 'bold' }}>Active Filters:</div>
      <Space wrap style={{ flex: 1 }}>
        {activeFilters.map(filter => (
          <Tag 
            key={filter.id} 
            closable 
            onClose={() => onRemoveFilter(filter.id)}
            color={getTagColor(filter.logicalOperator)}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Space>
              {`${filter.column} ${getOperatorDisplay(filter.operator)} ${filter.displayValue} (${filter.logicalOperator})`}
            </Space>
          </Tag>
        ))}
      </Space>
    </div>
  );
};

export default FiltersComponent; 