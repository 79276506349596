import React from "react";
import { Card } from "antd";

const OrgFatture = ({ org }) => {
  return (
    <Card title="Fatture" bordered={false}>
      <p>Contenuto per la sezione Fatture da implementare</p>
    </Card>
  );
};

export default OrgFatture; 