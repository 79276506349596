import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { useAuth0 } from "../react-auth0-spa";
import { Result } from "antd";
import { routeAccessConfig } from "../config";

// Function to check route access based on user permissions and route configuration
const checkRouteAccess = (path, user) => {
  // Default to requiring staff access
  let allowNonStaff = false;
  let requiresOfcAdmin = false;
  
  // Check if the path matches any of our route patterns
  for (const routeConfig of routeAccessConfig) {
    const regex = new RegExp(routeConfig.pattern);
    if (regex.test(path)) {
      allowNonStaff = routeConfig.allowNonStaff || false;
      requiresOfcAdmin = routeConfig.restrictAccessToOfcAdmin || false;
      break;
    }
  }
  
  return (user?.isOfcStaff || allowNonStaff) && 
         (!requiresOfcAdmin || user?.isOfcAdmin);
};

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const { loading, isAuthenticated, loginWithRedirect, user } = useAuth0();

  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: path }
      });
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect, path]);

  const render = props => {
    if (!isAuthenticated) {
      return null; // Will redirect via the useEffect hook
    }
    
    const hasAccess = checkRouteAccess(props.location.pathname, user);
    
    if (!hasAccess) {
      return (
        <Result
          status="403"
          title="Accesso Negato" 
          subTitle="Spiacenti, non sei autorizzato ad accedere a questa pagina."
        />
      );
    }
    
    return <Component {...props} />;
  };

  return <Route path={path} render={render} {...rest} />;
};

export default PrivateRoute;